import { Component } from 'react';
import ReactGA from "react-ga4";
import config from '../config';

class BasePage extends Component {

    constructor(props) {
        super(props);

        if (props.event === undefined && window.location) {
            console.log(`/2021${window.location.pathname}`)
            //window.location = `/2021${window.location.pathname}`
        }
        else {

            const pathname = window.location.pathname.replace(`${config.baseUrl}/`, "")
            window.sendUrl(pathname + window.location.search);

            ReactGA.send({ 
                hitType: "pageview", 
                page: pathname + window.location.search, 
                title: document.title
            });

            /*TagManager.dataLayer({
                dataLayer: {
                    event: 'pageview',
                    page: {
                        url: window.location.pathname + window.location.search,
                        title: document.title,
                    }
                }
            });*/
        }
    }
}


export default BasePage;