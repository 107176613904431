import axios from 'axios';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/**
 * Component to Top Header Festival slider
 */
class HeaderList extends Component {

    /**
     * Create HeaderList component
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            cards: [],
            slideIndex: 0
        };
    }

    /**
     * Component did mount
     * TODO: get cards
     */
    componentDidMount() {
        const cards = this.getLocalCards();
        this.refreshLocalCards();
        const slideIndex = this.processIsOpen(cards)
        this.setState({cards, slideIndex, isLoading: true});
    }

    getLocalCards() {
        let cards = localStorage.getItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/data");

        if (cards == null) {
            cards = [
                {
                    title: "BIG Festival",
                    link: "/games/",
                    order: 0
                },
                {
                    title: "BIG Publishers",
                    link: "/publishers/",
                    order: 1
                },
                {
                    title: "Panorama Brasil",
                    link: "/festival/panorama_brasil/",
                    order: 2
                }
            ];
        }
        else {
            cards = JSON.parse(cards);
        }

        return cards;
    }

    refreshLocalCards() {
        let update = true;
        //let lastDate = localStorage.getItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/date");
        //lastDate = moment(JSON.parse(lastDate));
    
        /*if (lastDate.isValid()) {
            const current = moment();
            const diff = current.diff(lastDate, 'hours');
            const diffDuration = moment.duration(diff);

            if (diffDuration < 2)
                update = false;
        }*/
        
        if (update) {
            const event = this.props.event
            let url = `/api/v1/${event}/${this.props.intl.locale}/festivalArea/`
            axios.get(url).then((res) => {
                const cards = res.data;
                cards.sort((a, b) => {
                    return (a.order ?? 1000) - (b.order ?? 1000)
                })
                localStorage.setItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/data", JSON.stringify(cards));
                localStorage.setItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/date", JSON.stringify(moment()));
                const slideIndex = this.processIsOpen(cards)
                this.setState({slideIndex, cards});
            });
        }
    }

    processIsOpen(cards) {
        let slideIndex = 0

        cards.forEach((item, index) => {
            item.open = false
            if (window.location.pathname.indexOf(item.link) >= 0) {
                item.open = true
                slideIndex = index
            }
        })

        return slideIndex
    }

    /**
     * Render all cards
     */
    renderCards() {
        return this.state.cards.map(item => {
            let image = ""
            let open = ""

            if (item.image)
                image = <img src={item.image} alt="Card"/>

            if (item.open)
                open = "open"

            return (
                <div className={`card-slide ${open}`} key={item.title}>
                    <a href={item.link} title={item.title}>
                        <p>{item.title}</p>
                        <div className="image-slide">
                            {image}
                        </div>
                    </a>
                </div>
            );
        });
    }

    /**
     * Render header festival list component
     */
    render() {
        if (!this.state.isLoading)
            return ""

        const settings = {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: this.state.slideIndex,
            autoplay: false,
            centerPadding: '180px',
            draggable: true,
            centerMode: false,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll:1,
                        centerPadding: '150px'
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        centerPadding: '150px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerPadding: '20px'
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '10px'
                    }
                },
                {
                    breakpoint: 465,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerPadding: '0'
                    }
                }
            ]
        };

        return (
            <header className="top-list">
                <div className="container">
                    <p className="title"><FormattedMessage id="header.list.title" /></p>
                    <div className="slider-responsive">
                        <Slider {...settings}>
                            {this.renderCards()}
                        </Slider>
                    </div>
                </div>             
            </header>
        );
    }
}

export default injectIntl(HeaderList);