import { injectIntl } from 'react-intl'
import React, { Component } from 'react'

class PlaylistVideos extends Component {
    constructor(props) {
        super(props);

        let current = null

        if (this.props.videos && this.props.videos.length > 0)
            current = this.props.videos[0]

        this.state = {
            current
        }
    }

    render() {
        return (
            <div className='row playlist_container'>
                <div className='col-12 col-md-8 playlist_video'>
                    <div className='playlist_video_container'>
                        {this.state.current && (
                            <iframe
                            width="100%"
                            height="315"
                            src={this.state.current.embedUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            ></iframe>
                        )}
                    </div>
                </div>
                <div className='col-12 col-md-4 playlist_list'>
                    {this.props.videos && this.props.videos.map((video, index) => {
                        return (
                            <div key={`video_thumbnail_${index}`}  
                                className={`playlist_item row ${this.state.current === video ? 'active' : ''}`}
                                onClick={() => this.setState({current: video})}>
                                <img src={video.thumbnail} alt={video.title} className='playlist_item_image col-12 col-md-6 d-flex' />
                                <div className='playlist_item_title col-12 col-md-6 d-flex align-items-center'>
                                    {video.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(PlaylistVideos);