import { FormattedMessage } from "react-intl";

export const GetCountry = (code, locale) => {
    if (locale.includes("-"))
        locale = locale.split('-')[0]

    const countries = {
        "pt": {
            germany: "Alemanha",
            brazil: "Brasil",
            canada: "Canadá",
            eua: "EUA",
            france: "França",
            greece: "Grécia",
        },
        "en": {
            brazil: "Brazil",
            canada: "Canada",
            eua: "EUA",
            france: "France",
            germany: "Germany",
            greece: "Greece",
        }
    }

    const countriesLocale = countries[locale];
    return countriesLocale[code];
}

/**
   * Get class by age
   * @param {number} age 
   */
export const GetAgeClass = (age) => {
    const ageClasses = {
        0: "green",
        10: "blue",
        12: "yellow",
        14: "orange",
        16: "orange",
        18: "black"
    }

    return ageClasses[age]
}

/**
   * Get label by age
   * @param {number} age 
   */
export const GetAgeSmallLabel = (age) => {

    if (age === 0)
        return "L";
    else
        return "+" + age;
}

export const GetFilterLabel = (age) => {
    if (age === '0')
        return <FormattedMessage id="game.list.classification.everyone" />
    else
        return <FormattedMessage id="up-to">{(message) => `${message} ${age}`}</FormattedMessage>
}