import './index.css';
import App from './App';
import React from 'react';
import axios from 'axios';
import config from './config';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from "react-intl";
import localeData from "./locales/languages.json";

axios.interceptors.request.use((requestConfig) => {
  requestConfig.baseURL = config.baseApiURL || '';
  return requestConfig;
});

const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

//<React.StrictMode>

ReactDOM.render(
  <IntlProvider locale={language} messages={messages}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
