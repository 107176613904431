import axios from 'axios';
import React, { Component } from 'react';
import { getRegistration } from "./Registration";
import { getNumberVisitors, getNumberViews } from "./siteData"
import { FormattedMessage, injectIntl } from "react-intl";

import iconNowWhite from "../assets/images/icon-visualizacoes-online.svg";
import iconViewsWhite from "../assets/images/icon-visualizacoes.svg";
import iconNowYellow from "../assets/images/icon-visualizacoes-online-amarelo.svg";
import iconViewsYellow from "../assets/images/icon-visualizacoes-amarelo.svg";

/**
 * Component to show page views
 */
class Views extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            viewsData: {}
        }
    }

    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/views/`

        const data = {
            area: this.props.area,
            slug: this.props.slug,
            id: getRegistration()?.id ?? null
        }

        axios.post(url, data).then((res) => {
            this.setState({viewsData: res.data, isLoading: false})
        });
    }

    /**
     * Render page views
     */
    render() {

        if (this.state.isLoading)
            return ""

        let iconNow = iconNowWhite;
        let iconViews = iconViewsWhite;

        if (this.props.color === "yellow") {
            iconNow = iconNowYellow;
            iconViews = iconViewsYellow;
        }

        let now = ""
        let views = ""

        if (this.state.viewsData.showViews || getNumberViews())
            views = <p><img src={iconViews} alt="" /> {this.state.viewsData.views} <FormattedMessage id="views" /></p>

        if (this.state.viewsData.showNow || getNumberVisitors())
            now = <p><img src={iconNow} alt=""/> {this.state.viewsData.now} <FormattedMessage id="now" /></p>

        return (
            <div className="visualizacoes-estande d-flex flex-row">
                {now}
                {views}
            </div>
        );
    }
}

export default injectIntl(Views);