import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import Select from "react-select"
import React, { Component } from 'react'
import ImageWelcome from "./ImageWelcome"
import Modal from "react-bootstrap/Modal"
import ReCAPTCHA from "react-google-recaptcha"
import { getTerms, getPrivacy } from "./siteData"
import { FormattedMessage, injectIntl } from "react-intl"



/**
 * Modal Registration Component
 */
class Registration extends Component {

    /**
     * Create modal registration component
     */
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          age: '',
          ageObject: null,
          genre: '',
          terms: false,
          message: '',
          token: ""
        };

        this.sendRegistration = this.sendRegistration.bind(this);
      }


    /**
     * Send registration to backend
     * @param {*} e 
     */
    sendRegistration(e) {
        e.preventDefault();
    
        const { email, age, genre, terms, token } = this.state;
        let gameAge = this.props.gameAge;
        if (gameAge === undefined)
            gameAge = 0;

        if (2021 - age < gameAge) {
            const message = <FormattedMessage id="registration.noAge" />;
            this.setState({message});
            return;
        }
    
        if (terms
            && email !== ""
            && age !== ""
            && genre !== "" && genre !== "-1") {
            let locale = this.props.intl.locale
            let event = this.props.event

            const url = `/api/v1/${event}/registration/`
            axios.post(url, { email, age, genre, locale, token })
            .then ((result) => {
                if (result.status === 200) {
                    if (!result.data.captcha) {
                        const message = <FormattedMessage id="vip.robot" />
                        this.setState({message})
                        window.grecaptcha.reset()
                        return
                    }

                    saveRegistration(Cookies.get("registration"), email, parseInt(age), result.data.confirmed, result.data.created)
                    this.props.onHide()
                }
            }).catch(error => {
                if (error.response.status === 412) {
                    const message = <FormattedMessage id="registration.error.email" />;
                    this.setState({message})
                }
            })
        }
        else {
            const message = <FormattedMessage id="registration.message" />;
            this.setState({message})
        }
    }

    /**
     * Set field form value: TODO: create generation this
     */
    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    onSelectChangeField = (name, data) => {
        this.setState({ [`${name}Object`]: data });
        this.setState({ [name]: data.value });
    }

    renderYearsOptions() {
        const years = []

        for (var i = 2020; i >= 1930; i--) years.push(i);

        return years.map(year => <option key={(`year_${year}`)} value={year}>{year}</option>);
    }

    getYearsOptions() {
        const years = []

        for (var i = 2020; i >= 1930; i--) 
            years.push({
                value: i,
                label: i
            });

        return years
    }

    setToken = (token) => {
        this.setState({token})
    }

    renderTermsText = (message) => {

        message = message[0]
        message = message.replace("[terms]", `<a href=${getTerms()} target="_new">`)
        message = message.replace("[/terms]", `</a>`)

        message = message.replace("[privacy]", `<a href=${getPrivacy()} target="_new">`)
        message = message.replace("[/privacy]", `</a>`)

        return (
            <p className="form-check-label" htmlFor="termos" dangerouslySetInnerHTML={{__html: message}} />
        )
    }

    /**
     * Render modal registration
     */
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} className="custom-modal" dialogClassName="cadastroModal">
                <div className="btn-close">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div className="row">
                    <div className="img-cadastro col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <ImageWelcome />
                    </div>
                    <div className="content-cadastro col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                        <h1 className="title h4 text-gray mb-4 pb-0"><FormattedMessage id="login.title" /></h1>

                        <form onSubmit={this.sendRegistration}>

                            <div className="form-group">
                                    <input type="email" name="email" className="form-control" aria-describedby="emailHelp" placeholder="E-mail" value={this.state.email} onChange={this.onChangeField}></input>
                                </div>
                                <div className="form-group">
                                    <Select
                                        placeholder="Ano de nascimento" 
                                        options={this.getYearsOptions()}
                                        className='react-select-container'
                                        classNamePrefix='react-select'
                                        value={this.state.ageObject}
                                        onChange={(data) => this.onSelectChangeField("age", data)}
                                    />
                                </div>
                                <div className="form-group">
                                    <select className="form-control" name="genre" placeholder="Sexo" value={this.state.genre} onChange={this.onChangeField}>
                                        <FormattedMessage id="genre">{(message) => <option value="-1">{message}</option>}</FormattedMessage>
                                        <FormattedMessage id="male">{(message) => <option value="0">{message}</option>}</FormattedMessage>
                                        <FormattedMessage id="female">{(message) => <option value="1">{message}</option>}</FormattedMessage>
                                        <FormattedMessage id="other">{(message) => <option value="2">{message}</option>}</FormattedMessage>
                                    </select>
                                </div>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input" id="termos" name="terms" checked={this.state.terms} onChange={this.onChangeField}></input>
                                    <FormattedMessage id="terms.text">{(message) => this.renderTermsText(message)}</FormattedMessage>
                                </div>
                                <div className="form-group">
                                    <ReCAPTCHA sitekey="6LcuzJUaAAAAALQTS6n9NeVXxFNrWpYZY7loztLW" onChange={this.setToken} />
                                </div>
                                <div className="form-group form-check">
                                    <p className="message">{this.state.message}</p>
                                </div>
                                <div className="actions d-flex flex-column">
                                    <button type="submit" className="btn text bold size-small text-white bg-orange w-100 mb-3"><FormattedMessage id="enter" /></button>
                                </div>


                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}


export const saveRegistration = (code, email, age, isConfirmed, created) => {
    const registration = {
        code: code,
        id: btoa(email),
        age: parseInt(age),
        mail: isConfirmed,
        date: created
    }

    localStorage.setItem('@bigjogos/registration/', JSON.stringify(registration))
}

export const getRegistration = () => {
    const regText = localStorage.getItem('@bigjogos/registration/')
    let registration = JSON.parse(regText)
    
    if (registration
        //&& registration.hasOwnProperty("code")
        && registration.hasOwnProperty("id")
        && registration.hasOwnProperty("mail")
        && registration.hasOwnProperty("date")) {
        registration.id = atob(registration.id)
        registration.date = moment(registration.date)
        return registration
    }

    return null
}

export const isRegistration = () => {
    const registration = getRegistration()

    if (registration)
        return true

    return false
}

export const isPreEmailConfirmed = () => {
    const registration = getRegistration()

    if (registration) {
        if (registration.mail)
            return true
        
        let diff = moment().diff(registration.date)
        diff = moment.duration(diff);

        return diff.asHours() < 4;
    }

    return false
}

export const isEmailConfirmed = () => {
    const registration = getRegistration()

    if (registration)
        return registration.mail

    return false
}

export const validateAge = (gameAge) => {
    const registration = getRegistration()
    
    if (!registration)
        return false
    
    
    return (2021 - registration.age >= gameAge)
}

export default injectIntl(Registration);