import axios from 'axios'
import { injectIntl } from 'react-intl'
import React, { Component } from 'react'
//import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";


class Advertising extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            advertising: {},
        }
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category
            || prevProps.area !== this.props.area) {
            this.updateData();
        }
    }

    updateData() {
        const event = this.props.event
        let url = `/api/v1/${event}/${this.props.intl.locale}/advertising/area/${this.props.area}`;

        if (this.props.category)
             url = `/api/v1/${event}/${this.props.intl.locale}/advertising/category/${this.props.area}/${this.props.category}`;

        axios.get(url).then((res) => {
            const advertising = res.data;
            if (advertising != null) {
                this.setState({advertising, isLoading: false})
                /*ReactGA.event({
                    category: `Advertising: ${advertising.slug}`,
                    action: "Show"
                })*/

                /*TagManager.dataLayer({
                    dataLayer: {
                        event: 'advertising',
                        advertising: {
                            slug: advertising.slug,
                            action: "Show"
                        }
                    }
                });*/

                ReactGA.event({
                    category: 'advertising',
                    action: 'Show',
                    label: advertising.slug,
                    value: 1, 
                    nonInteraction: true,
                    transport: "xhr",
                  });
            }
        });
    }

    renderImage(imageSrc) {
        if (this.state.advertising?.url) {
            return (
                <a href={this.state.advertising?.url} target="_blank" rel="noreferrer" title={this.state.advertising?.title}>
                    <img src={imageSrc} alt={this.state.advertising?.title}/>
                </a>
            )
        }
        else {
            return (
                <a target="_blank" rel="noreferrer" title={this.state.advertising?.title}>
                    <img src={imageSrc} alt={this.state.advertising?.title}/>
                </a>
            )
        }
    }

    render() {
        if (this.state.isLoading || this.state.advertising?.imagevertical == null)
            return "";

        return (
            <div className="marketing-left">
                <div className="vert">
                    {this.renderImage(this.state.advertising?.imagevertical)}
                </div>
                <div className="hor">
                    {this.renderImage(this.state.advertising?.imagehorizontal)}
                </div>
            </div>
        )
    }
}

export default injectIntl(Advertising);