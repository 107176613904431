import React, { Component } from 'react';
import './App.css';
import Home from './pages/Home';
import GamesList from './pages/GamesList';
import GameShow from './pages/GameShow';
import StandsList from './pages/StandsList';
import InfluencersList from './pages/InfluencersList'
import PublishersList from './pages/PublishersList'
import NotFound from './pages/NotFound'
import Coupons from './pages/Coupons'
import ConfirmEmail from './pages/ConfirmEmail';
import { injectIntl } from "react-intl";
import ReactGA from "react-ga4";
import axios from 'axios';
import moment from 'moment';
import config from './config';
import Loading from './components/Loading';

import Vip from './components/Vip'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


class App extends Component {

    constructor(props) {
        super(props)
        //ReactGA.initialize("G-EKLH42HEPN");
        this.state = {
            isLoading: true,
            vip: false,
            map: null
        };
    }

    runningInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    getEventSlug() {
        let baseUrl = window.location.pathname
        
        if (config.baseUrl && config.baseUrl.length > 0)
            baseUrl = baseUrl.replace(`${config.baseUrl}/`, "")

        const eventSlug = baseUrl.split('/')[1]

        return eventSlug
    }

    componentDidMount() {
        ReactGA.initialize('G-EKLH42HEPN')
        
        const urlNotEvent = [ "games", "festival", "stands", "publishers", "influencers", "game", "confirm", "coupons"]
        const eventSlug = this.getEventSlug()

        if (eventSlug && eventSlug.length > 0 && urlNotEvent.includes(eventSlug)) {
            const basename = window.location.pathname.replace(`${config.baseUrl}/`, "")
            this.findEvent(basename)
            return
        }

        if (eventSlug)
            this.validateEvent(eventSlug)
        else
            this.findEvent()
    }

    findEvent(basename) {
        let url = '/api/v1/home/current/'

        axios.get(url).then((res) => {
            let url = `${config.baseUrl}/${res.data}/`
            
            if (basename)
                url += basename
            else
                url += "home"

            if (!url.startsWith("/"))
                url = "/" + url

            window.location = url
        })
    }

    validateEvent(eventSlug) {
        const isLoading = false
        let url = `/api/v1/${eventSlug}/${this.props.intl.locale}/home/validate/`
        axios.get(url).then((res) => {
            localStorage.setItem("@bigjogos/site/", JSON.stringify(res.data))

            const map = res.data.map
            let diff = moment() >= moment(res.data.startdate)
            let vip = JSON.parse(localStorage.getItem("@bigjogos/vip/"))
            vip = diff || vip

            this.setState({vip, isLoading, map})
        });
    }

    vipOk= () => {
        localStorage.setItem('@bigjogos/vip/', "true");
        this.setState({vip: true})
    }

    renderLoading() {
        return (
        <div>
            <main role="main">
                <Loading type="page" />
            </main>
        </div>
        );
    }

    renderVip() {
        const eventSlug = this.getEventSlug()

        const retorno = (
            <main role="main" className="map imageMap" style={{minHeight: `95vh`, minWidth: `100vw`}}>
                {this.state.map && (
                    <img className="img-map" src={this.state.map} alt="map" style={{height: `100vh`, width: `100vw`}} />
                )}
                <Vip event={eventSlug} show={true} onHide={this.vipOk} />
            </main>
        )

        this.loaded = false;

        return retorno
    }

    render() {

        if (!this.runningInIframe() && config.isIframe) {
            const p = window.location.pathname
            window.location = `https://latam.gamescom.global/online/#${p}`
            return null
        }

        if (this.state.isLoading)
            return this.renderLoading()
        else if (!this.state.vip)
            return this.renderVip()

        return (
            <div className="App">
                <Router basename={config.baseUrl}>
                    <Switch>
                        <Route exact path={["/", "/:event/home/"]} render= {(matchProps) =>
                            <Home {...matchProps.match.params} />
                        } />
                        <Route exact path={[
                            "/:event/games/",
                            "/:event/games/category/:category/genre/:genre",
                            "/:event/games/category/:category",
                            "/:event/games/genre/:genre",
                            "/:event/festival/:festival/category/:category/genre/:genre",
                            "/:event/festival/:festival/category/:category",
                            "/:event/festival/:festival/genre/:genre",
                            "/:event/festival/:festival"
                        ]} render= {(matchProps) =>
                            <GamesList {...matchProps.match.params} />
                        } />
                        <Route exact path="/:event/game/:id" render= {(matchProps) =>
                            <GameShow {...matchProps.match.params} />
                        } />
                        <Route exact path={[
                            "/:event/publishers/:id",
                            "/:event/publishers/"
                        ]} render= {(matchProps) =>
                            <PublishersList {...matchProps.match.params} />
                        } />
                        <Route exact path={[
                            "/:event/stands/:id",
                            "/:event/stands/"
                        ]} render= {(matchProps) =>
                            <StandsList {...matchProps.match.params} />
                        } />
                        <Route exact path={[
                            "/:event/influencers/:id",
                            "/:event/influencers/"
                        ]} render= {(matchProps) =>
                            <InfluencersList {...matchProps.match.params} />
                        } />
                        <Route exact path="/:event/confirm/:mail" render= {(matchProps) =>
                            <ConfirmEmail {...matchProps.match.params} />
                        } />
                        <Route exact path="/:event/coupons/:slug" render= {(matchProps) =>
                            <Coupons {...matchProps.match.params} />
                        } />
                        <Route path='*' exact={true} component={NotFound} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default injectIntl(App);
