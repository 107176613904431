import React from 'react'
import BasePage from "./BasePage"
import { injectIntl, FormattedMessage } from "react-intl"
import Header from '../components/Header'
import Footer from '../components/Footer'
import HeaderList from '../components/HeaderList'

class NotFound extends BasePage {

    render() {
        return (
            <div>
                <Header />
                <main role="main">
                    <HeaderList />
                    <section className="content-list d-flex flex-row">                    
                        <div className="container-list">
                            <div className="content">
                                <div className="show-list">
                                    <div className="container notfound">
                                        <header>
                                            <h1 className="title float-left"><FormattedMessage id="notfound.title" /></h1>
                                        </header>

                                        <section className="list">
                                            <div className="row">
                                                <p><FormattedMessage id="notfound.description" /></p>
                                            </div>  
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}

export default injectIntl(NotFound);
