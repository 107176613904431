import axios from 'axios'
import React from 'react'
import ReactGA from "react-ga4";
import BasePage from "./BasePage";
import { Link } from "react-router-dom";
//import TagManager from 'react-gtm-module'
import { FormattedMessage, injectIntl } from "react-intl";


import Classification from "../components/Classification";
import Views from "../components/Views";
import Registration, { isPreEmailConfirmed, isRegistration } from '../components/Registration';
import Sponsors from "../components/Sponsors";
import PlayGame from '../components/PlayGame';
import ConfirmEmail from '../components/ConfirmEmail';
import Like from '../components/Like';

import iconFacebook from "../assets/images/icon-facebook.svg";
import iconTwitter from "../assets/images/icon-twitter.svg";
import iconWhatsapp from "../assets/images/icon-whats.svg";
import iconButtonPlay from "../assets/images/icon-controller.svg";
import iconAward from "../assets/images/icon-medal.svg";


class GameShow extends BasePage {

    constructor(props) {
        super(props);
        this.state = {
            currentGame: {},
            showConfirmEmail: false,
            showRegistration: false,
            showPlay: false,
            showSponsors: false,
            dataSponsor: null,
            showMoreAbout: false
        }
    }

    
    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/${this.props.intl.locale}/game/${this.props.id}`
        axios.get(url).then((res) => {            
            const currentGame = res.data;
            this.setState({currentGame})
            this.loadStand()
        }).catch((error) => {
            if (error.response?.status === 404) {
                window.open("/notfound", "_self")
            }
        });
    }

    loadStand() {
        if(this.state.currentGame.area) {
            let event = this.props.event
            let url = `/api/v1/${event}/${this.props.intl.locale}/stand/game/${this.props.id}`
            axios.get(url).then((res) => {
                const currentGame = this.state.currentGame
                currentGame.stand = res.data

                let showSponsors = false
                let dataSponsor = null
                
                if (currentGame.stand != null) {
                    showSponsors = true
                    dataSponsor = currentGame.stand
                }
                
                this.setState({currentGame, showSponsors, dataSponsor})
            }).catch((error) => {
            });
        }
    }

    linkOrRegistration = (e) => {
        e.preventDefault();

        this.onRegisterEvent("Show Play Modal Clicked")

        if (this.state.currentGame.play && !isRegistration()) {
            this.toggleRegistration();
        }
        else {
            if (!this.state.currentGame.play || isPreEmailConfirmed()) {
                this.setState({ showPlay: true });
            }
            else {
                this.showConfirmEmail();
            }
        }
    }

    showConfirmEmail = () => {
        this.setState({ showConfirmEmail: true });
    }

    /**
     * Toogle registration Modal
     */
    toggleRegistration = () => {
        this.setState({showRegistration: !this.state.showRegistration});
    }

    closeConfirmEmail = (code = null) => {
        this.setState({ showConfirmEmail: false })
        
        if (code === 1)
            this.toggleRegistration()
    }

    /**
     * Event after modal registration close.
     * if registration success, open link.
     */
    closeRegistration = () => {
        this.toggleRegistration();

        if (isPreEmailConfirmed()) {
            this.setState({ showPlay: true });
        }
    }

    onClosePlay = (status = 1) => {
        this.setState({ showPlay: false });
        
        if (this.state.currentGame.play
            && status === 1) {
            this.onRegisterEvent("Play Button Clicked")
            window.open(this.state.currentGame.play, "_new")
        }
        
        if (status === 2)
            this.toggleRegistration()
    }

    onCloseSponsors = () => {
        this.setState({showSponsors: !this.state.showSponsors});
    }

    containsItens(name) {
        return (name in this.state.currentGame
            && Array.isArray(this.state.currentGame[name]) 
            && this.state.currentGame[name].length > 0)
    }

    renderListLinks(name, url) {
        if (name in this.state.currentGame
            && Array.isArray(this.state.currentGame[name])) {
            return this.state.currentGame[name].map(item => 
                <a key={("link_" + item.title)} 
                    href={(url + item.slug)} 
                    className="btn-category"
                    title={item.title}>
                    {item.title}
                </a>
            );
        }
        return null;
    }

    renderButtonsActions() {
        if (Array.isArray(this.state.currentGame.actions)) {
            return this.state.currentGame.actions.map((item, index) => {
                let icon = null;
                if (item.icon)
                    icon = (<img src={item.icon} alt={item.label} />);

                return (
                    <a key={`action_${item.label}_${index}`}
                        href={item.link} title={item.label} 
                        onClick={() => this.onRegisterEvent(`${item.label} Button Clicked`)} 
                        target="_blank" rel="noreferrer"
                        className="btn btn-play text bold size-small text-white bg-orange"
                        >
                        {icon}
                        {item.label}
                    </a>
                );
            });
        }
    }

    renderHowToPlay() {
        if (Array.isArray(this.state.currentGame.controllers)) {
            const controllers = this.state.currentGame.controllers
            const normalControllers = controllers.filter((item) => !item.label)
            const customControllers = controllers.filter((item) => item.label)

            return (normalControllers.concat(customControllers)).map((item, index) => {
                if (!item.label) {
                    return (
                        <li key={(`key_${item.command}_${index}`)}>
                            {item.thumb && <img src={item.thumb} alt={item.action} />}
                            {!item.thumb && <img src={(`/images/comandos/${item.command}.png`)} alt={item.action} />}
                            <p>{item.action}</p>
                        </li>
                    )
                }
                else {
                    return (
                        <li key={(`key_${item.command}_${index}`)} className="otherCommand">
                            <img src={item.thumb} alt={item.action} />
                            <div>
                                <p>{item.action}</p>
                                <span>{item.label}</span>
                            </div>
                        </li>
                    )
                }
            });
        }
    }

    renderAreaBreadcrumb() {
        if (this.state.currentGame.area === "none"
            || this.state.currentGame.area === null 
            || this.state.currentGame.area === "")
            return ""
            let event = this.props.event
        return (
            <li className="breadcrumb-item">
                <Link to={(`/${event}/festival/${this.state.currentGame.area}`)} 
                    title={this.state.currentGame.titleArea}
                    className="text text-gray size-small mb-0 pb-0">
                    {this.state.currentGame.titleArea}
                </Link>
            </li>
        )
    }

    onRegisterEvent = (action) => {
        ReactGA.event({
            category: 'Game',
            action: action,
            label: this.state.currentGame.title,
            value: 1, 
            nonInteraction: true,
            transport: "xhr",
          });
    }

    render() {

        /*let powered = "";

        if (this.state.currentGame.powered
            && this.state.currentGame.powered.show)
            powered = (
                <div className="powered-by">
                    <span><FormattedMessage id="game.detail.poweredBy" /></span>
                    <div className="logo-powered">
                    <img src={this.state.currentGame.powered.thumb} alt={this.state.currentGame.powered.title}/>
                    </div>
                </div>
            )*/

        return (
            <>
                <main role="main" className="flex-shrink-0">
                    <header className="content-bread">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/${this.props.event}/home`} className="text text-gray size-small mb-0 pb-0" title="Home"><FormattedMessage id="home" /></Link>
                                    </li>
                                    {this.renderAreaBreadcrumb()}
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span className="text bold text-orange size-small mb-0 pb-0">
                                            {this.state.currentGame.title}
                                        </span>
                                    </li>
                                </ol>
                            </nav>
                        </div>             
                    </header>

                    <section className="game-detail">
                        <div className="container">
                            <section className="about-game">
                                <div className="full-info">
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="video">
                                                <iframe title="video" width="560" height="315" src={this.state.currentGame.video} frameBorder="0" allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>        
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="content">
                                                <div className="w-100 d-flex flex-row position-relative mb-3 pegi">
                                                    <Classification age={this.state.currentGame.age} />
                                                    {
                                                        this.state.currentGame.awards &&
                                                        <div className="award">
                                                            <img src={iconAward} alt="Jogo premiado"/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="w-100 mb-3">
                                                    <h1 className="title h1 text-white mb-0 pb-0">{this.state.currentGame.title}</h1>
                                                </div>
                                                <div className="about w-100 mb-3">
                                                    <p className={`text size-default text-gray text-view-more${this.state.showMoreAbout?" active":""}`}>{this.state.currentGame.about}</p>
                                                    {!this.state.showMoreAbout && (
                                                        <button type="button" 
                                                            className="btn btn-link title size-small text-orange btn-view-more"
                                                            onClick={() => this.setState({showMoreAbout: true})}>
                                                            <FormattedMessage id="game.detail.view_more" />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="share w-100 mb-4 d-flex flex-wrap align-items-start justify-content-between">
                                                    <div className="d-flex flex-row align-items-center social">
                                                        <span className="title size-small text-gray"><FormattedMessage id="game.detail.share" /></span>
                                                        <a href={(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`)} title="Facebook" target="_new">
                                                            <img src={iconFacebook} alt="Facebook" />
                                                        </a>
                                                        <a href={(`https://twitter.com/intent/tweet?&url=${encodeURIComponent(window.location.href)}&via=BIG_FESTIVAL`)} title="Twitter" target="_new">
                                                            <img src={iconTwitter} alt="Twitter" />
                                                        </a>
                                                        <a href={(`https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`)} title="WhatsApp" target="_new">
                                                            <img src={iconWhatsapp} alt="WhatsApp" />
                                                        </a>
                                                    </div>
                                                    <Views color="yellow" area="games" slug={this.props.id} event={this.props.event} />
                                                    {this.state.currentGame.slug && <Like event={this.props.event} area="games" slug={this.state.currentGame.slug} openRegistration={this.toggleRegistration} openEmailConfirmed={this.showConfirmEmail} />}
                                                </div>
                                                <div className="actions w-100 mb-3">
                                                    {this.renderButtonsActions()}
                                                    <a href={this.state.currentGame.play} 
                                                        data-toggle="modal" data-target="#cadastroModal" 
                                                        onClick={this.linkOrRegistration} title="Jogar"
                                                        className="btn btn-play text bold size-small text-white bg-orange" >
                                                        <img src={iconButtonPlay} alt="Jogar"/>
                                                        <FormattedMessage id="play" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="short-info">
                                    <div className="row">
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                                            <div className="country info">
                                                <h4 className="title h4 size-default text-gray mb-1 pb-0"><FormattedMessage id="country" /></h4>
                                                <p className="text size-default text-gray mb-0 pb-0">{this.state.currentGame.country}</p>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                                            {this.state.currentGame.publisher &&
                                                <div className="publisher info">
                                                    <h4 className="title h4 size-default text-gray mb-1 pb-0"><FormattedMessage id="game.list.publisher" /></h4>
                                                    <p className="text size-default text-gray mb-0 pb-0">{this.state.currentGame.publisher}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                                            {this.state.currentGame.studio &&
                                                <div className="publisher info">
                                                    <h4 className="title h4 size-default text-gray mb-1 pb-0"><FormattedMessage id="studio" /></h4>
                                                    <p className="text size-default text-gray mb-0 pb-0">{this.state.currentGame.studio}</p>
                                                </div>
                                            }
                                        </div>
                                        { this.containsItens("categories") &&
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                                                <div className="info">
                                                    <h4 className="title h4 size-default text-gray mb-3 pb-0">
                                                        <FormattedMessage id="categories" />
                                                    </h4>
                                                    <div className="categories">
                                                        <div className="options">
                                                            {this.renderListLinks("categories", `/${this.props.event}/games/category/`)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        { this.containsItens("genres") &&
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                                                <div className="genres info">
                                                    <h4 className="title h4 size-default text-gray mb-3 b-0">
                                                        <FormattedMessage id="game.list.genre" />
                                                    </h4>
                                                    <div className="categories">
                                                        <div className="options">
                                                            {this.renderListLinks("genres", `/${this.props.event}/games/genre/`)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                
                                {this.state.currentGame.controllers 
                                    && this.state.currentGame.controllers.length > 0 && (
                                    <div className="how-to-play">
                                        <div className="mb-4">
                                            <h3 className="title h4 size-default text-gray mb-3 b-0"><FormattedMessage id="game.detail.howPlay" /></h3>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            {this.state.currentGame.controllers.map((item, index) => (
                                                item.thumb && (
                                                    <figure>
                                                        <img src={item.thumb} alt="Controls"/>
                                                    </figure>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                    </section>
                </main>
                <Registration 
                    event={this.props.event}
                    show={this.state.showRegistration}
                    gameAge={this.state.currentGame.age}
                    onHide={this.closeRegistration} />
                <PlayGame
                    show={this.state.showPlay}
                    instructions={this.state.currentGame.instructions}
                    playLink={this.state.currentGame.play}
                    age={this.state.currentGame.age}
                    onHide={this.onClosePlay} />
                <ConfirmEmail
                    event={this.props.event}
                    show={this.state.showConfirmEmail}
                    onHide={this.closeConfirmEmail} />
                <Sponsors
                    show={this.state.showSponsors}
                    value={this.state.dataSponsor}
                    event={this.props.event}
                    partner={true}
                    area="stands"
                    slug={this.state.dataSponsor?.slug}
                    onHide={this.onCloseSponsors} />
            </>
        );
    }
}

export default injectIntl(GameShow);