import axios from 'axios';
import { injectIntl } from "react-intl";
import React, { Component } from 'react';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: ""
        };
    }

    componentDidMount() {
        const footer = this.getLocalHeader();
        this.refreshLocalHeader();

        this.setState({footer});
    }

    getLocalHeader() {
        return "";
        /*let cards = localStorage.getItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/data");

        if (cards == null) {
            cards = [
                {
                    title: "BIG Festival",
                    link: "/games/"
                },
                {
                    title: "BIG Publishers",
                    link: "/publishers/"
                },
                {
                    title: "Panorama Brasil",
                    link: "#"
                },
                {
                    title: "PlayStation",
                    link: "#"
                },
                {
                    title: "Xbox",
                    link: "#"
                },
                {
                    title: "Konami",
                    link: "#"
                },
                {
                    title: "Nintendo",
                    link: "#"
                },
                {
                    title: "Estandes",
                    link: "/stands/"
                },
                {
                    title: "EA",
                    link: "#"
                },
                {
                    title: "Riot Games",
                    link: "#"
                }
            ];
        }
        else {
            cards = JSON.parse(cards);
        }

        return cards;*/
    }

    refreshLocalHeader() {
        let update = true;
        /*let lastDate = localStorage.getItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/date");
        lastDate = moment(JSON.parse(lastDate));
    
        if (lastDate.isValid()) {
            const current = moment();
            const diff = current.diff(lastDate, 'hours');
            const diffDuration = moment.duration(diff);

            if (diffDuration < 2)
                update = false;
        }*/
        
        if (update) {
            let url = `/api/v1/${this.props.intl.locale}/footer/`;
            axios.get(url).then((res) => {
                this.setState({footer: res.data})
                //const cards = res.data;
                //localStorage.setItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/data", JSON.stringify(cards));
                //localStorage.setItem('@bigjogos/festivalArea/' + this.props.intl.locale + "/date", JSON.stringify(moment()));
                //this.setState({cards});
            });
        }
    }


    render() {
        return (
            <div dangerouslySetInnerHTML={{__html: this.state.footer}} />
        )
    }
}

export default injectIntl(Footer);