import moment from 'moment'

const getData = (key) => {
    let data = JSON.parse(localStorage.getItem("@bigjogos/site/"))
    return data[key]
}

export const getPrivacy = () => {
    return getData("privacy")
}

export const getTerms = () => {
    return getData("terms")
}

export const getStartDate = () => {
    return moment(getData("startdate"))
}

export const getStartDateVip = () => {
    return moment(getData("startdatevip"))
}

export const getNumberVisitors = () => {
    return getData("numbervisitors")
}

export const getNumberViews = () => {
    return getData("numberviews")
}

export const getPins = () => {
    return getData("pins")
}

export const getMap = () => {
    return getData("map")
}

export const getHomeType = () => {
    return getData("hometype") || 0
}