import axios from 'axios';
import Slider from "react-slick";
import { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage, injectIntl } from "react-intl"

import PlaylistVideos from '../PlaylistVideos';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { text } from 'body-parser';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: []
        }
    }

    componentDidMount() {          
        let url = `/api/v1/${this.props.event}/${this.props.intl.locale}/home/page`

        axios.get(url).then((res) => {
            const data = res.data;
            console.log(data);
            this.setState({data, isLoading: false});
        });
      }

    renderBanner = () => {
        return (
            <section className="content-news d-flex flex-wrap justify-content-between">
                <div className="row">
                    {this.state.data &&
                    this.state.data.banners && 
                    this.state.data.banners.map((banner, index) => {
                        return (
                            <article key={`article_banner_${index}`} 
                                     id={`article_banner_${index}`} 
                                     className="news col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <FormattedMessage id="home.more">
                                {(text) => (
                                    <>
                                        <figure className="thumb text-start mb-3">
                                            {banner.link.startsWith("http") && (
                                                <a key={`article_banner_${index}`} 
                                                    href={banner.link} 
                                                    title={text}
                                                    target={banner.link.startsWith("http") ? " _blank" : ""}
                                                    rel="noreferrer">
                                                    {banner.image && (
                                                        <img src={banner.image} alt={banner.title}/>
                                                    )}
                                                    {!banner.image && (
                                                        <img src="/images/img-thumb-no-image.jpg" alt={banner.title} />
                                                    )}
                                                </a>
                                            )}
                                            {!banner.link.startsWith("http") && (
                                                <HashLink key={`article_banner_${index}`} 
                                                    href={banner.link} 
                                                    title={text}
                                                    target={banner.link.startsWith("http") ? " _blank" : ""}
                                                    rel="noreferrer">
                                                    {banner.image && (
                                                        <img src={banner.image} alt={banner.title}/>
                                                    )}
                                                    {!banner.image && (
                                                        <img src="/images/img-thumb-no-image.jpg" alt={banner.title} />
                                                    )}
                                                </HashLink>
                                            )}
                                        </figure>
                                        <div className="content text-center text-md-start">
                                            <h3 className="title h3 text-white mb-2 pb-0">{banner.title}</h3>
                                            <p className="text size-default text-gray mb-2 pb-0">{banner.description}</p>
                                            {banner.link.startsWith("http") && (
                                                <a href={banner.link} 
                                                    title={text} 
                                                    target={banner.link.startsWith("http") ? " _blank" : ""}
                                                    rel="noreferrer" 
                                                    className="btn view-more text bold size-default text-orange">
                                                    {text}  
                                                </a>
                                            )}
                                            {!banner.link.startsWith("http") && (
                                                <HashLink to={banner.link} 
                                                    title={text} 
                                                    target={banner.link.startsWith("http") ? " _blank" : ""}
                                                    rel="noreferrer" 
                                                    className="btn view-more text bold size-default text-orange">
                                                    {text}  
                                                </HashLink>
                                            )}
                                        </div>
                                    </>
                                )}
                                </FormattedMessage>
                            </article>
                        )
                    })}
                </div>
            </section>
        )
    }

    renderGames = () => {
        return (
            <section className="content-games d-flex flex-wrap justify-content-between">
                <div className="row">
                    {this.state.data &&
                     this.state.data.games &&
                     this.state.data.games.map((game, index) => {
                        return (
                            <article key={`article_game_${index}`} 
                                     id={`article_game_${index}`} 
                                     className="game col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
                                <FormattedMessage id="home.more">{(text) => (
                                    <>
                                        <figure className="thumb text-center text-md-start mb-3">
                                            <Link key={`article_game_${index}`} to={`/${this.props.event}/game/${game.slug}`} title={text} target="">
                                                {game.thumb && (
                                                    <img src={game.thumb} alt={game.title}/>
                                                )}
                                                {!game.thumb && (
                                                    <img src="/images/img-thumb-no-image.jpg" alt={game.title} />
                                                )}
                                            </Link>
                                        </figure>
                                        <div className="content text-center text-md-start">
                                            <h3 className="title h3 text-white mb-2 pb-0">{game.title}</h3>
                                            <p className="text size-default text-gray mb-2 pb-0">{game.about}</p>
                                            <Link to={`/${this.props.event}/game/${game.slug}`} title={text} target="" className="btn view-more text bold size-default text-orange">
                                                {text}
                                            </Link>
                                        </div>
                                    </>)} 
                                </FormattedMessage>
                            </article>
                        )
                     })
                    }
                    <div className="actions col-12 text-center">
                        <FormattedMessage id="home.showAllGames">
                            {(text) => (
                                <Link to={`/${this.props.event}/games/`} title={text} target="" className="btn text bold size-small text-white bg-orange">
                                    {text}
                                </Link>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
            </section>
        )
    }


    renderTalks = () => {
        const settings = {
            autoplay: false,
            dots: false,
            arrows: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 982,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };

        return (
            <section className="content-talks d-flex flex-wrap justify-content-between">
                <div className="w-100">
                    <Slider {...settings} className="carousel-slick mb-5">
                        {this.state.data &&
                        this.state.data.talks &&
                        this.state.data.talks.map((talk, index) => {
                            return (
                                <article key={`article_talk_${index}`}  
                                        id={`article_talk_${index}`} 
                                        className="talk">
                                    <FormattedMessage id="home.more">{(text) => (
                                        <>
                                            <figure className="thumb text-center text-md-start mb-3">
                                                <a key={`article_talk_${index}`} href={talk.link} title={text} target="_blank" rel="noreferrer">
                                                    {talk.image && (
                                                        <img src={talk.image} alt={talk.title} />
                                                    )}
                                                    {!talk.image && (
                                                        <img src="/images/img-thumb-no-image.jpg" alt={talk.title} />
                                                    )}
                                                </a>
                                            </figure>
                                            <div className="content text-center text-md-start">
                                                <h3 className="title h3 text-white mb-2 pb-0">{talk.title}</h3>
                                                <a href={talk.link} title={text} target="_blank" rel="noreferrer" className="btn view-more text bold size-default text-orange">
                                                    {text}
                                                </a>
                                            </div>
                                        </>
                                    )}</FormattedMessage>
                                </article>
                            )
                        })}

                    </Slider>
                    <div className="actions col-12 text-center">
                        <FormattedMessage id="home.showAllTalks">{(text) => (
                            <a href={this.state.data.link_talks}
                                title={text}  target="_blank" rel="noreferrer"
                                className="btn text bold size-small text-white bg-orange">
                                {text}
                            </a>
                        )}</FormattedMessage>
                    </div>                        
                </div> 

            </section>

        )
    }


    render() {
        return (
            <main className="flex-shrink-0">
                <section className="highlight mb-0">
                    <div className="container">
                        <section className="content-title mb-4" style={{marginTop: "5rem"}}>
                            <h1 className="title h1 text-white text-center mb-0 pb-0"><FormattedMessage id="home.title" /></h1>
                        </section>
                        <section className="content-subtitle mb-3">
                            <h2 className="title h2 text-white text-center mb-3 pb-0">{this.state.data.subtitle}</h2>
                            <p className="text size-default text-gray text-center mb-0 pb-0">{this.state.data.description}</p>
                        </section>
                        {this.renderBanner()}
                    </div>
                </section>
                <section id="jogos" className="games fullscreen mb-4">
                    <div className="container-fluid">
                        <section className="content-title mb-4">
                            <h2 className="title h1 text-white text-center mb-0 pb-0">{this.state.data.games_title}</h2>
                        </section>
                        {this.renderGames()}
                    </div>
                </section>
                {this.state.data.talks && (
                    <section id="palestras" className="talks">
                        <div className="container">
                            <section className="content-title mb-4">
                                <h2 className="title h1 text-white text-center mb-0 pb-0">{this.state.data.talk_title}</h2>
                            </section>
                            {this.renderTalks()}
                        </div>
                    </section>
                )}
                {this.state.data &&
                this.state.data.videos && (
                    <section className="mt-5 mb-4">
                        <div className="container">
                            <section className="content-title mb-4">
                                <h2 className="title h1 text-white text-center mb-0 pb-0">{this.state.data.playlist_title}</h2>
                            </section>

                            <PlaylistVideos videos={this.state.data.videos} />
                            <div className="actions col-12 text-center mt-5">
                                <FormattedMessage id="home.showEvent">{(text) => (
                                    <a href={this.state.data.link_playlist} title={text} target="_blank" rel="noreferrer"
                                        className="btn text bold size-small text-white bg-orange">
                                        {text}
                                    </a>
                                )}</FormattedMessage>
                            </div>
                        </div>
                    </section>
                )}
            </main>
        )
    }
}

export default injectIntl(withRouter(HomePage));