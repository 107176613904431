import axios from 'axios';
import React from 'react';
import BasePage from "./BasePage";
import { FormattedMessage, injectIntl } from "react-intl";
import HeaderList from '../components/HeaderList';
import Advertising from '../components/Advertising';
import Sponsors from "../components/Sponsors";
import Header from '../components/Header';
import Footer from '../components/Footer';

class PublishersList extends BasePage {

    constructor(props) {
        super(props);

        this.state = {
            area: "big_publishers",
            sponsorArea: "big_publishers",
            response: {},
            visited: [],
            filteredPublishers: [],
            orderBy: 0,
            showSponsors: false,
            dataSponsor: null,
            titleSponsor: "Big Publishers",
            filterMobileActive: false
        }
    }

    /**
     * Component did mount
     * 1 - Get gamelist data
     */
    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/${this.props.intl.locale}/publishers/`
        axios.get(url).then((res) => {
            const response = res.data;
            let filteredPublishers = [];

            if ("publishers" in response) {
                filteredPublishers = this.orderPublishersBy(0, response["publishers"])
            }

            let visited = localStorage.getItem('@bigjogos/publishers/visited');

            if (visited == null)
                visited = [];
            else
                visited = JSON.parse(visited);

            this.setState({response, filteredPublishers, visited});

            if (this.props.id) {
                this.showSponsor(null, this.props.id);
            }
            else if (response.sponsor != null) {
                this.setState({showSponsors: true, dataSponsor: response.sponsor, sponsorArea: "stands"});
            }
        });
    } 

    toggleMobileFilter = () => {
        let filterMobileActive = !this.state.filterMobileActive
        this.setState({filterMobileActive})
      }

    orderPublishersBy = (orderBy, filteredPublishers = null) => {        
        if (orderBy === undefined)
            orderBy = this.state.orderBy

        if (filteredPublishers == null)
            filteredPublishers = [...this.state.response["publishers"]]

        switch (orderBy) {
            case 1:
                filteredPublishers.sort((f, s) => {
                if (f.title.toLowerCase() < s.title.toLowerCase())
                    return -1;
                if (f.title.toLowerCase() > s.title.toLowerCase())
                    return 1;
                return 0;
                });
                break;
            
            default:
                filteredPublishers.sort(() =>0.5 - Math.random())
                break;
        }

        return filteredPublishers
    }

    onOrderBy = (e) => {
        const orderBy = Number(e.target.value)
        const filteredPublishers = this.orderPublishersBy(orderBy)
        
        /*if (orderBy === undefined)
            orderBy = this.state.orderBy;

        let filteredPublishers = [...this.state.response["publishers"]];

        switch (orderBy) {
            case 1:
                filteredPublishers.sort((f, s) => {
                if (f.title.toLowerCase() < s.title.toLowerCase())
                    return -1;
                if (f.title.toLowerCase() > s.title.toLowerCase())
                    return 1;
                return 0;
                });
                break;
            
            default:
                filteredPublishers.sort(() =>0.5 - Math.random())
                break;
        }*/

        this.setState({ filteredPublishers, orderBy });
    }

    onPublisherClick = (e, slug) => {
        let visited = this.state.visited;

        if (!visited.includes(slug)) {
            visited.push(slug);
            this.setState({ visited });
            localStorage.setItem('@bigjogos/publishers/visited', JSON.stringify(visited));
        }

        this.showSponsor(e, slug);
    }

    onCloseSponsors = () => {
        this.setState({showSponsors: !this.state.showSponsors});
    }

    showSponsor = (e, slug) => {
        e?.preventDefault();
        let stand = null;

        this.state.response["publishers"].forEach(item => {
            if (item.slug === slug) {
                stand = item;
            }
        });

        if (stand != null) {
            this.setState({showSponsors: true, dataSponsor: stand, sponsorArea: "big_publishers", titleSponsor: null});
        }
    }

    renderPublishers() {
        return this.state.filteredPublishers.map(item => {
            let active = "";
            if (this.state.visited.includes(item.slug))
                active = " active";

            let thumb = <img src="/images/img-card-publisher-no-image.jpg" alt=""/>
            if (item.thumb)
                thumb = <img src={item.thumb} alt=""/>

            return (
                <div key={("publisher_" + item.slug)} className={("card model-publisher" + active)}>
                    <button className="link-button" data-toggle="modal" data-target="#publisherModal" onClick={(e) => this.onPublisherClick(e, item.slug)} >
                        <div className="image">
                            {thumb}
                        </div>
                        <div className="info">
                            <h2 className="title">{item.title}</h2>
                        </div>
                    </button>
                </div>
            );
        });
    }

    render () {
        return (
            <div>
                <Header />
                <main role="main" style={{paddingTop: "100px"}}>
                    <HeaderList event={this.props.event} />
                    <section className="content-list d-flex flex-wrap">
                        <div className={`filter ${(this.state.filterMobileActive ? "active":"")}`}>
                            <div className="container">
                                <button className="showFilterMobile" onClick={this.toggleMobileFilter}>{(this.state.filterMobileActive ? "-":"+")}</button>
                                <form>
                                    <div className="row">
                                        <div className="filters col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                        </div>
                                        <div className="order col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                            <div className="order-by">
                                                <label className="float-left"><FormattedMessage id="game.list.orderby" /></label>
                                                <div className="form-group float-left">
                                                    <select className="form-control form-control-sm" onChange={this.onOrderBy}>
                                                        <FormattedMessage id="game.list.orderby.random">{(message) => <option value="0">{message}</option>}</FormattedMessage>
                                                        <FormattedMessage id="game.list.orderby.alphabetic">{(message) => <option value="1">{message}</option>}</FormattedMessage>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> 
                        <div className="container-list">
                            <Advertising area={this.state.area} event={this.props.event} />
                            <div className="content">
                                <div className="show-list">
                                    <div className="container">
                                        <header>
                                            <h1 className="title float-left">BIG Publishers</h1>
                                        </header>
                                        
                                        <section className="list">
                                            <div className="row">
                                                { this.renderPublishers() }
                                            </div>  
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <Advertising area={this.state.area} event={this.props.event} />
                        </div>
                    </section>
                    <Sponsors
                        show={this.state.showSponsors}
                        value={this.state.dataSponsor}
                        title={this.state.titleSponsor}
                        area={this.state.sponsorArea}
                        event={this.props.event}
                        slug={this.state.dataSponsor?.slug}
                        onHide={this.onCloseSponsors} />
                </main>
                <Footer />
            </div>
        );
    }

}

export default injectIntl(PublishersList);