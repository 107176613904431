import Views from "./Views"
import React, { Component } from 'react'
import ReactGA from "react-ga4";
import Modal from "react-bootstrap/Modal"
import { FormattedMessage, injectIntl } from "react-intl"


class Sponsors extends Component {

    onLinkClick = (name) => {
        console.log(`Stand: ${this.props.slug}`)
        console.log(`Link ${name} Clicked`)

        /*ReactGA.event({
            category: `Stand: ${this.props.slug}`,
            action: `Link ${name} Clicked`
        })*/

        /*TagManager.dataLayer({
            dataLayer: {
                event: 'stand',
                stand: {
                    slug: this.props.slug,
                    action: `Link ${name} Clicked`
                }
            }
        });*/

        ReactGA.event({
            category: 'stand',
            action: 'click',
            label: this.props.slug,
            value: 1, 
            nonInteraction: true,
            transport: "xhr",
          });

    }

    renderPinPoints() {
        return (
            <div className="points">
                {this.props.value?.pins?.map((pin, index) => {
                    let left = 0;
                    let top = 0;

                    if ("left" in pin)
                        left = pin["left"];

                    if ("top" in pin)
                        top = pin["top"];

                    const marginLeft = -16;
                    const marginTop = -16;

                    left = `${left}%`;
                    top = `${top}%`;

                    return (
                        <div key={(`pinPoints_${this.props.name}_${index}`)} className="point" style={{left, top, marginLeft, marginTop }}>
                            <a href={pin.link} onClick={() => this.onLinkClick(pin.title)} target={pin.target} data-toggle="tooltip" data-placement="right" title={pin.title} ></a>
                            <div className="efeito"></div>
                        </div>
                    );
                })}                 
            </div>
        );
    }

    render() {

        if (this.props.show) {
            /*ReactGA.event({
                category: `Stand: ${this.props.slug}`,
                action: `Show`
            })*/

            /*TagManager.dataLayer({
                dataLayer: {
                    event: 'stand',
                    stand: {
                        slug: this.props.slug,
                        action: `Show`
                    }
                }
            });*/

            ReactGA.event({
                category: 'stand',
                action: 'Show',
                label: this.props.slug,
                value: 1, 
                nonInteraction: true,
                transport: "xhr",
              });
        }

        return (
            <Modal show={this.props.show} className="custom-modal" onHide={this.props.onHide}>
                <div className="btn-close">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="content estande">
                    <div className="image">
                        <div className="points">
                            <div className="point">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="right" title="clique e tenha acesso apromoções exclusivas"></a>
                                <div className="efeito"></div>
                            </div>
                            {this.renderPinPoints()}
                        </div>
                        <figure>
                            <img src={this.props.value?.map} alt="map" /> 
                        </figure>
                        <Views area={this.props.area} slug={this.props.slug} event={this.props.event} />
                    </div>
                </div>
            </Modal>
        )
    }
}

export default injectIntl(Sponsors);