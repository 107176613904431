import React from 'react'
import axios from 'axios'
import moment from 'moment'
import BasePage from "./BasePage"
import Header from '../components/Header'
import Footer from '../components/Footer'
import ReCAPTCHA from "react-google-recaptcha"
import HeaderList from '../components/HeaderList'
import ConfirmEmail from '../components/ConfirmEmail'
import { FormattedMessage, injectIntl } from "react-intl"
import Registration, { isRegistration, isEmailConfirmed, getRegistration } from '../components/Registration'


class Coupons extends BasePage {
    
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            title: "",
            code: null,
            next: null,
            showCaptcha: false,
            erroCaptcha: false,
            showConfirmEmail: false,
            showRegistration: false,
            text: null
        }
    }

    componentDidMount() {
        if (!isRegistration()) {
            this.setState({ showRegistration: true })
        }
        else if (!isEmailConfirmed()) {
            this.setState({ showConfirmEmail: true })
        }
        else {
            this.showCaptcha()
        }
    }

    closeRegistration = () => {

        if (isRegistration()) {
            if (isEmailConfirmed()) {
                this.setState({ showRegistration: false })
                this.showCaptcha()
                return
            }
            else {
                this.setState({ showRegistration: false, showConfirmEmail: true })
                return
            }
        }

        window.history.back()
    }

    closeConfirmEmail = (code = null) => {
        if (code === 1) {
            this.setState({showRegistration: true})
            return
        }
        
        if (isEmailConfirmed()) {
            this.setState({ showConfirmEmail: false })
            this.showCaptcha()
            return
        }
        
        window.history.back()
    }

    showCaptcha() {
        this.setState({ showCaptcha: true })
    }

    generateCoupon = (token) => {
        let event = this.props.event
        const url = `/api/v1/${event}/${this.props.intl.locale}/coupon/${this.props.slug}`
        // const locale = this.props.intl.locale.split("-")[0]
        const registration = getRegistration()

        const data = {
            email: registration.id,
            token
        }

        console.log(data)

        axios.post(url, data).then((res) => {
            const { title, code } = res.data

            let text = res.data.text

            this.setState({ title, code, text, isLoading: false, showCaptcha: false })
        }).catch((error) => {
            if (error.response?.status === 404) {
                window.open("/notfound", "_self")
            }

            if (error.response?.status === 401) {
                this.setState({showCaptcha: true})
                window.grecaptcha.reset()
            }

            if (error.response?.status === 425) {
                const { next, title, text } = error.response.data

                this.setState({next, title, text, isLoading: false, showCaptcha: false})
            }
        });
    }

    renderPage() {
        if (this.state.showCaptcha)
            return this.renderCaptcha()

        if (this.state.isLoading) 
            return this.renderLoading()

        if (this.state.code != null)
            return this.renderCode()

        return this.renderNoCanCode()
    }

    renderCode() {
        let message = <FormattedMessage id="coupon.text" />

        if (this.state.text)
            message = this.state.text

        return (
            <div className="container notfound">
                <header>
                    <h1 className="title float-left"><FormattedMessage id="coupon.title">{(message) => message.toString().replace("{title}", this.state.title)}</FormattedMessage></h1>
                </header>

                <section className="list">
                    <div className="row">
                        <p dangerouslySetInnerHTML={{__html: message}}></p>
                        <h3><b>{this.state.code}</b></h3>
                    </div>  
                </section>
            </div>
        )
    }

    renderNoCanCode() {
        let next = ""

        if (this.state.next) {
            const locale = this.props.intl.locale.split("-")[0]
            const date = moment(this.state.next)

            let dateText = date.format("DD/MM/yyyy HH:mm")

            if (locale === "en")
                dateText = date.format("MM/DD/yyyy HH:mm")

            let message = <FormattedMessage id="coupon.next" />

            if (this.state.text)
                message = this.state.text

            next = (
                <div className="row">
                    <p dangerouslySetInnerHTML={{__html: message}}></p>
                    <h3><b>{dateText}</b></h3>
                </div>
            )
        }
        else {
            next = <div className="row"><p><FormattedMessage id="coupon.limit" /></p></div>
        }

        return (
            <div className="container notfound">
                <header>
                    <h1 className="title float-left"><FormattedMessage id="coupon.title">{(message) => message.toString().replace("{title}", this.state.title)}</FormattedMessage></h1>
                </header>

                <section className="list">
                    {next}
                </section>
            </div>
        )
    }

    renderCaptcha() {
        return (
            <div className="container notfound">
                <header>
                    <h1 className="title float-left"><FormattedMessage id="coupon.captcha.title" /></h1>
                </header>

                <section className="list">
                    <div className="row">
                        <ReCAPTCHA sitekey="6LcuzJUaAAAAALQTS6n9NeVXxFNrWpYZY7loztLW" onChange={this.generateCoupon} />
                        { this.state.erroCaptcha &&
                        <p><FormattedMessage id="coupon.captcha.robot" /></p>
                        }
                    </div>  
                </section>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="container notfound">
                <section className="list">
                    <div className="row">
                        <h4><FormattedMessage id="loading" /></h4>
                    </div>  
                </section>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <main role="main">
                    <HeaderList event={this.props.event} />
                    <section className="content-list d-flex flex-row">                    
                        <div className="container-list">
                            <div className="content">
                                <div className="show-list">
                                    {this.renderPage()}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
                <Registration 
                    event={this.props.event}
                    show={this.state.showRegistration}
                    gameAge={0}
                    onHide={this.closeRegistration} />
                <ConfirmEmail
                    event={this.props.event}
                    show={this.state.showConfirmEmail}
                    onHide={this.closeConfirmEmail} />
            </div>
        );
    }

}


export default injectIntl(Coupons)