import axios from 'axios';
import React from 'react';
import BasePage from "./BasePage";
import { FormattedMessage, injectIntl } from "react-intl";
import HeaderList from '../components/HeaderList';
import Advertising from '../components/Advertising';
import Sponsors from "../components/Sponsors";
import Header from '../components/Header';
import Footer from '../components/Footer';
import "../assets/css/main_style.css";

class StandsList extends BasePage {

    constructor(props) {
        super(props);

        this.state = {
            area: "expositores",
            response: {},
            filteredStands: [],
            orderBy: 0,
            showSponsors: false,
            dataSponsor: null,
            titleSponsor: null,
            filterMobileActive: false
        }
    }

        /**
     * Component did mount
     * 1 - Get gamelist data
     */
    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/${this.props.intl.locale}/stands/`
        axios.get(url).then((res) => {
            const response = res.data;
            let filteredStands = [];

            if ("stands" in response) {
                filteredStands = this.orderStandsBy(0, response["stands"])
            }
            
            this.setState({response, filteredStands});

            if (this.props.id) {
                this.showSponsor(null, this.props.id);
            }
            else if (response.sponsor != null) {
                this.setState({showSponsors: true, dataSponsor: response.sponsor, titleSponsor: "Estandes"});
            }
        });
    }

    toggleMobileFilter = () => {
        let filterMobileActive = !this.state.filterMobileActive
        this.setState({filterMobileActive})
      }

    orderStandsBy = (orderBy, filteredStands = null) => {
        if (orderBy === undefined)
            orderBy = this.state.orderBy

        if (filteredStands == null)
            filteredStands = [...this.state.response["stands"]];

        switch (orderBy) {
            case 1:
                filteredStands.sort((f, s) => {
                if (f.title.toLowerCase() < s.title.toLowerCase())
                    return -1;
                if (f.title.toLowerCase() > s.title.toLowerCase())
                    return 1;
                return 0;
                });
                break;
            
            default:

                filteredStands.sort(() =>0.5 - Math.random())
                break;
        }

        return filteredStands
    }

    onOrderBy = (e) => {
        const orderBy = Number(e.target.value);
        const filteredStands = this.orderStandsBy(orderBy)

        this.setState({ filteredStands, orderBy });
    }

    onCloseSponsors = () => {
        this.setState({showSponsors: !this.state.showSponsors});
    }

    showSponsor = (e, slug) => {
        e?.preventDefault();
        let stand = null;

        this.state.response["stands"].forEach(item => {
            if (item.slug === slug) {
                stand = item;
            }
        });

        if (stand != null) {
            this.setState({showSponsors: true, dataSponsor: stand, titleSponsor: null});
        }
    }

    renderStands() {
        return this.state.filteredStands.map(item => {
            let thumb = <img src="/images/img-thumb-no-image.jpg" alt=""/>
    
            if (item.thumb)
                thumb = <img src={item.thumb} alt=""/>

            return (
                <div key={("stand_" + item.slug)} className="card model-publisher">
                    <button className="link-button" onClick={(e) => this.showSponsor(e, item.slug)} data-toggle="modal" data-target="#patrocinadoresModal">
                        <div className="image">
                            {thumb}
                        </div>
                        <div className="info">
                            <h2 className="title">{item.title}</h2>
                        </div>
                    </button>
                </div>
            );
        });
    }

    render() {
        return (
            <div>
                <Header />
                <main role="main" style={{paddingTop: "100px"}}>
                    <HeaderList event={this.props.event} />
                    <section className="content-list d-flex flex-wrap">
                        
                        <div className={`filter ${(this.state.filterMobileActive ? "active":"")}`}>
                            <div className="container">
                                <button className="showFilterMobile" onClick={this.toggleMobileFilter}>{(this.state.filterMobileActive ? "-":"+")}</button>
                                <form>
                                    <div className="row">                                   
                                        <div className="order col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="order-by">
                                                <label className="float-left"><FormattedMessage id="game.list.orderby" /></label>
                                                <div className="form-group float-left">
                                                    <select className="form-control form-control-sm" onChange={this.onOrderBy}>
                                                        <FormattedMessage id="game.list.orderby.random">{(message) => <option value="0">{message}</option>}</FormattedMessage>
                                                        <FormattedMessage id="game.list.orderby.alphabetic">{(message) => <option value="1">{message}</option>}</FormattedMessage>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> 
                        <div className="container-list">
                            <Advertising area={this.state.area} event={this.props.event} />
                            <div className="content">
                                <div className="show-list">
                                    <div className="container">
                                        <header>
                                            <h1 className="title float-left"><FormattedMessage id="stands.list.title" /></h1>
                                        </header>
                                        <section className="list">
                                            <div className="row">
                                                { this.renderStands() }
                                            </div>  
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <Advertising area={this.state.area} event={this.props.event} />
                        </div>
                    </section>
                    <Sponsors
                        event={this.props.event}
                        show={this.state.showSponsors}
                        value={this.state.dataSponsor}
                        title={this.state.titleSponsor}
                        area={this.state.area}
                        slug={this.state.dataSponsor?.slug}
                        onHide={this.onCloseSponsors} />
                </main>
                <Footer />
            </div>
        );
    }
}

export default injectIntl(StandsList);