import { Component } from 'react';
import { getPins, getMap } from "../siteData"
import { FormattedMessage, injectIntl } from "react-intl"

class Map extends Component {
    constructor(props) {
        super(props);

        this.isScrolling = false;

        this.state = {
            pins: [],
            scrollLeft: 0, 
            scrollTop: 0,
            clientX: 0, 
            clientY:0,
            divHeight: 0,
            divWidth: 0,
            width: 0,
            height: 0
        }
    }

    componentDidMount() {
        const divHeight = this?.divElement?.clientHeight;
        const divWidth = this?.divElement?.clientWidth;
    
        const isLoading = false;
    
        window.addEventListener('resize', this.updateDimensions)
    
        const pins = getPins()
        const img = getMap()
        this.setState({divHeight, divWidth, pins, img, isLoading})
        setTimeout(this.centerScroll, 300)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onMouseUp =  () => {
        this.isScrolling = false;
        this.setState({scrollLeft: 0, scrollTop: 0,
                       clientX: 0, clientY:0});
    };
    
    onMouseDown = (event) => {
        this.isScrolling = true;
        const {scrollLeft, scrollTop} = this.divElement;
        this.setState({scrollLeft, scrollTop, clientX:event.clientX, clientY:event.clientY});
    };
    
    onWheel = (event) => {
        var maxScrollLeft = (this.divElement?.scrollWidth ?? 0) - (this.divElement?.clientWidth ?? 0)
        var maxScrollTop = (this.divElement?.scrollHeight ?? 0) - (this.divElement?.clientHeight ?? 0)
    
        if (maxScrollTop > 0) {
          this.divElement.scrollTop = this.divElement.scrollTop + event.deltaY
        }
        else if (maxScrollLeft > 0) {
          this.divElement.scrollLeft = this.divElement.scrollLeft + event.deltaY
        }
    }
    
    onMouseMove = (event) => {
        event.preventDefault();
    
        if (this.isScrolling) {
          const {clientX, scrollLeft, scrollTop, clientY} = this.state;
    
          this.divElement.scrollLeft = scrollLeft - (event.clientX - clientX);
          this.divElement.scrollTop = scrollTop - ( event.clientY - clientY);
        }
    };
    
    handleTouchStart = (event) => {
        this.isScrolling = true;
        const {scrollLeft, scrollTop} = this.divElement;
    
        this.setState({scrollLeft, scrollTop, clientX:event.touches[0].clientX, clientY:event.touches[0].clientY});
    }
    
    handleTouchMove = (event) => {
        const {clientX, scrollLeft, scrollTop, clientY} = this.state;
        
        if (this.isScrolling) {
          this.divElement.scrollLeft = scrollLeft - (event.touches[0].clientX - clientX);
          this.divElement.scrollTop = scrollTop - (event.touches[0].clientY - clientY);
        }
    }
    
    handleTouchEnd = (event) => {
        this.isScrolling = false;
        this.setState({scrollLeft: 0, scrollTop: 0,
                       clientX: 0, clientY:0});
    }
    
    handleTouchCancel = () => {
          console.log('cancel')
    }

    updateDimensions = () => {
        const divHeight = this.divElement?.clientHeight;
        const divWidth = this.divElement?.clientWidth;
        let width = this.state.width
        let height = this.state.height

        let wDiff = 3840 / divWidth
        let hDiff = 2160 / divHeight;

        if (hDiff > wDiff) {
            width = divWidth
            height = (2160 * divWidth) / 3840
        } else {
            width = (3840 * divHeight) / 2160
            height = divHeight
        }

        this.setState({width, height, divHeight, divWidth})
    };

    centerScroll = () => {
        var maxScrollLeft = (this.divElement?.scrollWidth ?? 0) - (this.divElement?.clientWidth ?? 0);
        var maxScrollTop = (this.divElement?.scrollHeight ?? 0) - (this.divElement?.clientHeight ?? 0);
        this.divElement.scrollLeft = maxScrollLeft / 2;
        this.divElement.scrollTop = maxScrollTop / 2;
    }

    imageLoaded = () => {
        this.updateDimensions()
        setTimeout(this.centerScroll, 300)
    }



    renderImage() {
        return (
            <div className="home-map" ref={ (divElement) => { this.divElement = divElement }} 
                onMouseDown={this.onMouseDown} 
                onMouseMove={this.onMouseMove} 
                onMouseUp={this.onMouseUp}
                onWheel={this.onWheel}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                onTouchCancel={this.handleTouchCancel}>
                <div className="contentPins" style={{height: `100vh`, width: `${this.state.width}px`}}>
                <img className="img-map" src={this.state.img} alt="map" style={{height: `100vh`, width: `${this.state.width}px`}} onLoad={this.imageLoaded} />
                {this.renderPins()}
                </div>
                <div className="alert-view">
                  <p><FormattedMessage id="map.dragMap" /></p>
                </div>
            </div>
        );
    }

    renderPins() {
        let localeCode = this.props.intl.locale.split('-')[0];
    
        if (localeCode !== "en" && localeCode !== "pt")
          localeCode = "en"
    
        return this.state.pins?.map((item, index) => {

          let left = 0;
          let top = 0;
    
          if ("left" in item)
            left = item["left"];
    
          if ("top" in item)
            top = item["top"];
    
          const marginLeft = -40;
          const marginTop = -34;
    
          left = `${left}%`;
          top = `${top}%`;
    
          return (
            <div key={(`mapPin_${index}`)} className={(`pin ${localeCode} ${item.title}`)} style={{left, top, marginLeft, marginTop }}>
              <a href={item.link} target={item.target} title={item.title} onClick={this.linkOrRegistration}>
                <img src={(`/images/pins/${this.props.event}/${localeCode}_rosa_${item.title}.png`)} alt={item.title} />
                <img src={(`/images/pins/${this.props.event}/${localeCode}_azul_${item.title}.png`)} className="img-top" alt={item.title} />     
              </a>
            </div>
          );
        })
    }

    render() {
        return (
            <main role="main" className="map">
                <section className="cover-container d-flex justify-content-center align-itens-center">
                    {this.renderImage()}
                </section>
            </main>
        );
    }
}

export default injectIntl(Map);