import axios from 'axios';
import React from 'react';
import BasePage from "./BasePage";
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from "react-intl";
import { GetAgeClass, GetFilterLabel, GetAgeSmallLabel } from '../components/DataHelper';
import Sponsors from "../components/Sponsors";
import Header from '../components/Header';
import Loading from '../components/Loading';
import LineAd from '../components/LineAd';


/**
 * Page Game List Component
 */
class GamesList extends BasePage {
  
  /**
   * Create Game List page component
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      title: "Big Festival",
      area: "big_festival",
      response: {},
      filteredGames: [],
      categories: [],
      genres: [],
      platforms: [],
      classification: [],
      containsFilter: false,
      orderBy: 0,
      showSponsors: false,
      dataSponsor: null,
      filterMobileActive: false,
      categoriesMobileActive: false,
    }
  }

  /**
   * Component did mount
   * 1 - Get gamelist data
   */
  componentDidMount() {
    let area = this.state.area
    let event = this.props.event

    if (this.props.festival)
      area = this.props.festival;
      
    let url = `/api/v1/${event}/${this.props.intl.locale}/games/${area}`
    let categories = this.state.categories
    let genres = this.state.genres

    axios.get(url).then((res) => {
      if (this.props.category) {
        categories = []
        categories.push(this.props.category);
      }

      if (this.props.festival) {
        area = this.props.festival;
      }

      if (this.props.genre) {
        genres = []
        genres.push(this.props.genre);
      }

      const response = res.data
      
      const title = response.title
      let filteredGames = [];

      if ("games" in response) {
        filteredGames = response["games"];
      }

      let showSponsors = false
      let dataSponsor = null

      if (response.stand != null) {
        showSponsors = true
        dataSponsor = response.stand
      }

      const filters = {categories, genres}
      filteredGames = this.filterGames(response["games"], filters, null);
      this.setState({response, filteredGames, showSponsors, dataSponsor, categories, genres, area, title, isLoading: false});
    });
  }
   
  /**
   * Filter games and after call order by.
   * @param {Number} orderBy 
   */
  filterGames(games, filters, orderBy) {
    
    if (games == null)
      games = this.state.response["games"];

    let categories = filters?.["categories"]
    let genres = filters?.["genres"]
    let platforms = filters?.["platforms"]
    let classification = filters?.["classification"]

    if (categories == null)
      categories = this.state.categories

    if (!genres)
      genres = this.state.genres

    if (!platforms)
      platforms = this.state.platforms

    if (classification == null)
      classification = this.state.classification

    let filteredGames = games.filter(function(item) {
      let filter = true;

      if (categories.length > 0) {        
        if (!item.categories || item.categories.length <= 0) {
            filter = false
        }
        else {
          for (let i = 0; i < categories.length; i++) {
            let categorie = categories[i]
            if (item.categories.includes(categorie)) 
              filter &= true;
            else
              filter = false;
          }
        }
      }

      if (genres.length > 0 ) {
          if (!item.genres || item.genres.length <= 0) {
            filter = false
          }
          else {
            for (let i = 0; i < genres.length; i++) {
              let genre = genres[i];

              if (item.genres.includes(genre)) {
                filter &= true;
              } else
                filter = false;
            }
          }
      }

      if (platforms.length > 0) {
        for (let i = 0; i < platforms.length; i++) {
          let platform = platforms[i];

          if (item.powered === platform)
            filter &= true;
          else
            filter = false;
        }
    }

      if (classification.length > 0) {
        const maxAge = classification[0];
        //if (classification.includes(item.age))
        if (item.age <= maxAge)
          filter &= true;
        else
          filter = false;
      }

      return filter;
    });


    return this.orderByGames(filteredGames, orderBy);
  }

  /**
   * Order by games
   * @param {any[]} filteredGames 
   * @param {Number} orderBy 
   */
  orderByGames(filteredGames, orderBy) {
    if (orderBy === undefined)
      orderBy = this.state.orderBy;

    switch (orderBy) {
      case 1:
        filteredGames.sort((f, s) => {
          if (f.title.toLowerCase() < s.title.toLowerCase())
              return -1;
          if (f.title.toLowerCase() > s.title.toLowerCase())
            return 1;
        return 0;
        });
        break;

      case 2:
        filteredGames.sort((f, s) => {
          return f.age - s.age;
        });
        break;
      
      default:
        filteredGames.sort(() =>0.5 - Math.random())
    }

    return filteredGames;
  }

  /**
   * Event to create new filter
   * @param {Event} e 
   */
  onNewFilter = (e) => {
    let listFilter = this.state[e.target.name];
    const filterMobileActive = false

    if (e.target.name === "categories"
      || e.target.name === "platforms"
      || e.target.name === "classification") {
      listFilter = [e.target.value];
    }
    else {
      if (!listFilter.includes(e.target.value))
        listFilter.push(e.target.value);
    }
    
    const filteredGames = this.filterGames(null, { [e.target.name]: listFilter })
    this.setState({filteredGames, filterMobileActive, [e.target.name]: listFilter})
  }

  onCategoryFilter = (e) => {
    if (e.target.value) {
      const ne = {
        target: {
          name: "categories",
          value: e.target.value
        }
      }
      
      this.onNewFilter(ne)
    }
    else {
      this.onRemoveFilter(e, "categories", -1)
    }
  }

  /**
   * Event to remove filter item
   * @param {Event} e 
   * @param {String} type 
   * @param {Number} id 
   */
  onRemoveFilter = (e, type, id) => {
    e.preventDefault();
    let listFilter = this.state[type];
    const index = listFilter.indexOf(id);

    if (index > -1) {
      listFilter.splice(index, 1);  
      const filter = { [type]: listFilter}
      const filteredGames = this.filterGames(null, filter)
      this.setState({filteredGames, [type]: listFilter})
    }
  }

  /**
   * Event to order itens
   * @param {Event} e 
   */
  onOrderBy = (e) => {
    let orderBy = Number(e.target.value);
    const filteredGames = this.filterGames(null, null, orderBy);
    this.setState({filteredGames, orderBy})
  }

  onCloseSponsors = () => {
      this.setState({showSponsors: !this.state.showSponsors});
  }

  toggleMobileFilter = () => {
    let filterMobileActive = !this.state.filterMobileActive
    this.setState({filterMobileActive})
  }

  toggleCategoriesFilter = () => {
    let categoriesMobileActive = !this.state.categoriesMobileActive
    this.setState({categoriesMobileActive})
  }

  /**
   * Render current filter applied
   */
  renderCurrentFilter() {
    let filters = [];

    let categories = this.state.categories
    let genres = this.state.genres
    let platforms = this.state.platforms
    let classification = this.state.classification
    let containsFilter = false

    categories.forEach((item) => {
      let title = this.state.response["categories"]?.find(c => { return c.slug === item })?.title;
      filters.push({ type: "categories", value: item, title});
    });

    genres.forEach((item) => {
      let title = this.state.response["genres"]?.find(c => { return c.slug === item })?.title;
      filters.push({ type: "genres", value: item, title});
    });

    platforms.forEach((item) => {
      let title = this.state.response["platforms"]?.find(c => { return c.slug === item })?.title;
      filters.push({ type: "platforms", value: item, title});
    });

    classification.forEach((item) => {
      let title = GetFilterLabel(item);
      filters.push({ type: "classification", value: item, title});
    });

    if (filters.length > 0 && !containsFilter) {
      containsFilter = true;
    }
    else if (filters.length <= 0 && containsFilter) {
      containsFilter = false;
    }

    if (filters.length > 0) {
      return (
        <div className="result-filter">
          { this.state.containsFilter ? <p className="type"><FormattedMessage id="game.list.filters" /></p> : null }
          {filters.map(item =>
            <button className="result-button" key={(item.type + "_" + item.value)} title={item.title} onClick={(e) => this.onRemoveFilter(e, item.type, item.value)}>{item.title}</button>
          )}
        </div>
      )
    }

    return ""
  }

  /**
   * Render filter options by name
   * @param {String} name 
   */
   renderFilterOptions(name, filter, messageId, nameProperty = null) {
    let resp = this.state.response;

    if (nameProperty == null) nameProperty = name

    if (name in resp && Array.isArray(resp[name])) {

      let itensFilter = [];

      this.state.response.games?.forEach(item => { 

        if (Array.isArray(item?.[nameProperty])) {
          item?.[nameProperty]?.forEach(slug => {
            if (!itensFilter.includes(slug)) itensFilter.push(slug)
          })
        }
        else {
          if (!itensFilter.includes(item?.[nameProperty]) 
            && item?.[nameProperty] != null) {
               itensFilter.push(item?.[nameProperty])
          }
        }
      })

      const optionsList = resp[name]

      optionsList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
        
        if (b.title.toLowerCase() > a.title.toLowerCase()) return -1
        
        return 0
      })

      const options = optionsList.map(item => {
        if (!this.state[name].includes(item.slug) && itensFilter.includes(item.slug)) {
          return (<option key={(name + "_" + item.slug)} value={item.slug}>{item.title}</option>);
        }

        return null;
      });

      if (itensFilter.length > 0) {
        return (
          <select className="form-control form-control-sm" name={name} value="-1" onChange={filter}>
            <FormattedMessage id={messageId}>{(message) => <option>{message}</option>}</FormattedMessage>
            {options}
          </select>
        )
      }

      return "";
    }
  }

  renderCategoriesOptions(filter) {
    let resp = this.state.response;

    let nameProperty = "categories"

    if (nameProperty in resp && Array.isArray(resp[nameProperty])) {

      let itensFilter = [];

      this.state.response.games?.forEach(item => { 

        if (Array.isArray(item?.[nameProperty])) {
          item?.[nameProperty]?.forEach(slug => {
            if (!itensFilter.includes(slug)) itensFilter.push(slug)
          })
        }
        else {
          if (!itensFilter.includes(item?.[nameProperty]) 
            && item?.[nameProperty] != null) {
               itensFilter.push(item?.[nameProperty])
          }
        }
      })

      const optionsList = resp[nameProperty]

      optionsList.sort((a, b) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
        
        if (b.title.toLowerCase() > a.title.toLowerCase()) return -1
        
        return 0
      })

      const options = optionsList.map(item => {
        if ( itensFilter.includes(item.slug)) {
          const activeClass = this.state[nameProperty].includes(item.slug) ? "active" : ""
          return (
            <button type='button' key={(nameProperty + "_" + item.slug)} className={`btn-category ${activeClass}`} value={item.slug} onClick={this.onCategoryFilter}>{item.title}</button>
          );
        }

        return null;
      });

      if (itensFilter.length > 0) {        
        return (
          <div className="options active">
            {options}
          </div>
        )
      }

      return "";
    }
  }

  /**
   * Render link button for game
   */
  renderGamesLink() {
    if (this.state.filteredGames.length <= 0)
      return (<p><FormattedMessage id="game.list.notfound" /></p>)


    return this.state.filteredGames.map(item => {
      let thumb = <img src="/images/img-thumb-no-image.jpg" alt=""/>
      let awards = ""

      if (item.awards)
        awards = "award"
      
      if (item.thumb)
        thumb = <img src={item.thumb} alt=""/>

      return (
        <div className={(`card ${awards}`)} key={("game_" + item.slug)}>
          
          <Link to={(`/${this.props.event}/game/${item.slug}`)} title={item.title}>


            <div className="visual">
                <div className={`age color ${GetAgeClass(item.age)}`}>
                    <p className="text text-white size-small mb-0">{GetAgeSmallLabel(item.age)}</p>
                </div>
                <figure className="image">
                  {thumb}
                </figure>
            </div>
            <div className="info">
                <h3 className="title size-default text-white text-center mb-1 pb-0">{item.title}</h3>
                <p className="country text size-small text-gray text-center mb-0 pb-0">{item.country}</p>
            </div>
          </Link>
        </div>
      )}
    );
  }

  renderLoading() {
    return (<Loading style="page" />)
  }

  /**
   * Render game list page
   */
  render() {

    if (this.state.isLoading === true)
      return this.renderLoading();

    return (
      <div className="d-flex flex-column h-100 bg-black text-white">
        <main role="main" className="flex-shrink-0">
          <Header />
          <LineAd />
          <section className="filter-games mb-4">
            <div className="container">
              <form className="mb-4">
                <div className="filter-mobile">
                    <p className="text text-gray size-small mb-0 pb-0"><FormattedMessage id="game.list.filter" /></p>
                    <button type="button" className="showFilterMobile" onClick={this.toggleMobileFilter}>{(this.state.filterMobileActive ? "-":"+")}</button>
                </div>
                <div className={`order-by ${(this.state.filterMobileActive ? "active":"")}`}>
                  <div className="row">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      {this.renderFilterOptions("genres", this.onNewFilter, "game.list.genre")}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      <select className="form-control form-control-sm" name="classification" value="-1" onChange={this.onNewFilter}>
                        <FormattedMessage id="game.list.classification">{(message) => <option>{message}</option>}</FormattedMessage>
                        <FormattedMessage id="game.list.classification.everyone">{(message) => <option value="0">{message}</option>}</FormattedMessage>
                        <FormattedMessage id="up-to">{(message) => <option value="10">{message} 10</option>}</FormattedMessage>
                        <FormattedMessage id="up-to">{(message) => <option value="12">{message} 12</option>}</FormattedMessage>
                        <FormattedMessage id="up-to">{(message) => <option value="14">{message} 14</option>}</FormattedMessage>
                        <FormattedMessage id="up-to">{(message) => <option value="16">{message} 16</option>}</FormattedMessage>
                      </select>
                    </div>
                    <div className="ms-auto col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                      <select className="form-control form-control-sm" onChange={this.onOrderBy}>
                        <FormattedMessage id="game.list.orderby.random">{(message) => <option value="0">{message}</option>}</FormattedMessage>
                        <FormattedMessage id="game.list.orderby.alphabetic">{(message) => <option value="1">{message}</option>}</FormattedMessage>
                        <FormattedMessage id="game.list.orderby.agerange">{(message) => <option value="2">{message}</option>}</FormattedMessage>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="filter-mobile">
                    <p className="text text-gray size-small mb-0 pb-0">Show more categories</p>
                    <button type="button" className="showCategoriesMobile" onClick={this.toggleCategoriesFilter}>{(this.state.categoriesMobileActive ? "+":"-")}</button>
                </div>
                <div className={`categories categoriesButtons ${this.state.categoriesMobileActive ? "active" : ""}`}>
                  {this.renderCategoriesOptions(this.onNewFilter)}
                  {this.renderCurrentFilter()}
                </div>
              </form>


              <section className="game-list">
                <div className="list d-flex flex-wrap justify-content-start">
                  {this.renderGamesLink()}
                </div>
              </section>
            </div>
          </section>
          <Sponsors
            show={this.state.showSponsors}
            value={this.state.dataSponsor}
            title={this.state.title}
            event={this.props.event}
            area="stands"
            slug={this.state.dataSponsor?.slug}
            onHide={this.onCloseSponsors} />
          
        </main>
      </div>
    );
  }
}

export default injectIntl(GamesList);
