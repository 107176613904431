import axios from 'axios';
import { FormattedMessage, injectIntl } from "react-intl";
import React, { Component } from 'react';
import { isRegistration, isEmailConfirmed, getRegistration } from '../components/Registration';

class Like extends Component {

    constructor(props) {
        super(props);
        this.state = {
            likes: 0,
            liked: false,
            message: ""
        }
    }

    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/like/${this.props.area}/${this.props.slug}/none`;
        
        if (isRegistration())
            url = `/api/v1/${event}/like/${this.props.area}/${this.props.slug}/${btoa(getRegistration().id)}`;

        axios.get(url).then((res) => {
            this.setState({likes: res.data.count, liked: res.data.liked})
        });
    }

    updateLike = () => {
        let event = this.props.event
        const siteConfig = JSON.parse(localStorage.getItem("@bigjogos/site/"))

        if (siteConfig.vote) {
            if (!isRegistration()) {
                this.props.openRegistration && this.props.openRegistration()
            }
            else if (!isEmailConfirmed()) {
                this.props.openEmailConfirmed && this.props.openEmailConfirmed()
            }
            else {
                let url = `/api/v1/${event}/like`;
                let like = {
                    area: this.props.area,
                    slug: this.props.slug,
                    email: getRegistration().id
                }

                axios.post(url, like).then((res) => {
                    console.log(res.data)
                    this.setState({likes: res.data.count, liked: res.data.liked})
                }).catch((error) => {
                    if(error.response.status === 425) {
                        const message = <FormattedMessage id="like.closed" />
                        this.setState({message})
                    }
                })
            }
        }
        else {
            const message = <FormattedMessage id="like.closed" />
            this.setState({message})
        }
    }

    render () {
        let liked = ""

        if (this.state.liked === true) {
            liked = "liked"
        }

        return (
            <div className="like">
                <button type="button" className="like-button" onClick={this.updateLike}>
                    <span className="title size-small text-gray"><FormattedMessage id="like" /></span>
                    <span className="icon-like"></span> 
                    <span className="number-like">{this.state.likes}</span>
                </button>
                <p className="like-message text size-small text-magenta mb-0 pb-0">{this.state.message}</p>
            </div>
        )
    }
}


export default injectIntl(Like);