import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl";

class LineAd extends Component {

    render() {
        return (
            <section className="ads">
                <div className="container">
                    <div className="cols">
                        <div className="title-brand">   
                            <p className="text size-small mb-0"></p>
                        </div>
                        <div className="brands">
                            <figure>
                                <img src="/images/ads/ads-lei-incentivo-a-cultura.png" alt="Lei de Incentivo à Cultura" />
                            </figure>    
                        </div>
                    </div>
                    <div className="cols">
                        <div className="title-brand">   
                            <p className="text size-small mb-0">
                                <FormattedMessage id="sponsor" />
                            </p>
                        </div>
                        <div className="brands">
                            <figure>
                                <img src="/images/ads/ads-claro.svg" alt="Claro" />
                            </figure>
                            <figure>
                                <img src="/images/ads/ads-sabesp.png" alt="SABESP" />
                            </figure>
                        </div>
                    </div>
                    <div className="cols">
                        <div className="title-brand">   
                            <p className="text size-small mb-0"><FormattedMessage id="realization" /></p>
                        </div>
                        <div className="brands">
                        <figure>
                                <img src="/images/ads/ads-ministerio-cultura.png" alt="Ministério da Cultura" />
                            </figure>
                            <figure>
                                <img src="/images/ads/ads-governo-sp.png" alt="Governo do estado de São Paulo" />
                            </figure>
                            <figure>
                                <img src="/images/ads/ads-ministerio-cultura-gov-federal.png" alt="Ministério da Cultura"/>
                            </figure>
                            <figure>
                                <img src="/images/ads/ads-governo-federal.png" alt="Governo Federal" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default injectIntl(LineAd)