import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

import img_loading from '../assets/images/loading.svg'

class Loading extends Component {
    constructor(props) {
        super(props);
    }

    getContainerStyle = () => {
        return {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '800px',
            width: '100vw'
        }
    }

    render() {
        return (
            <div className="loading">
                <div className="loading__container" style={this.getContainerStyle()}>
                    <div className="loading__logo">
                        <img src={img_loading} alt="Logo" />
                    </div>
                    <div className="loading__text">
                        <FormattedMessage id="loading" />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(Loading);