
import React from 'react'
import Cookies from 'js-cookie'
import BasePage from "./BasePage"
import { injectIntl } from "react-intl"
import { getHomeType } from "../components/siteData"
import Registration from '../components/Registration'

import Map from '../components/Home/Map'
import LineAd from '../components/LineAd'
import Loading from '../components/Loading'
import HomePage from '../components/Home/HomePage'


/**
 * Home Page Component
 */
class Home extends BasePage {
  
  /**
   * Create Home page component
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.loaded = false;
    this.state = {
      isLoading: false,
      showRegistration: false,
      afterRegistration: null,
      targetRegistration: "_self",
    };
  }

  /**
   * Component did mount
   * 1 - Get home map pins
   */
  componentDidMount() {

  }


  /**
   * Open link or registration modal
   * @param {*} e 
   */
  linkOrRegistration = (e) => {
    /*let registration = Cookies.get("registration");

    if (registration === undefined) {
      e.preventDefault();
      this.setState({
          afterRegistration: e.target.parentElement.href,
          targetRegistration: e.target.parentElement.target
      });
      this.toggleRegistration();
    }*/
  }

  /**
   * Toogle registration Modal
   */
  toggleRegistration() {
    this.setState({showRegistration: !this.state.showRegistration});
  }

  /**
   * Event after modal registration close.
   * if registration success, open link.
   */
  closeRegistration = () => {
    this.toggleRegistration();
    let registration = Cookies.get("registration");

    if (registration !== undefined) {
      window.open(this.state.afterRegistration, this.state.targetRegistration);
    }
  }


  renderMap() {
    return (
      <div>
        <Map event={this.props.event} />
        <Registration 
          event={this.props.event}
          show={this.state.showRegistration}
          onHide={this.closeRegistration} />
      </div>
    );
  }

  renderHome() {
    var lineAd = <LineAd />;
    return (
      <div>
        <HomePage event={this.props.event} />
        <Registration 
          event={this.props.event}
          show={this.state.showRegistration}
          onHide={this.closeRegistration} />
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <main role="main">
          <Loading style="page" />
        </main>
      </div>
    );
  }

  /**
   * Render all Home page
   */
  render() {
    if (this.state.isLoading)
      return this.renderLoading();
    else if (getHomeType() === 1)
      return this.renderHome()
    else
      return this.renderMap();
  }
}

export default injectIntl(Home);