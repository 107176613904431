import axios from 'axios'
import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal"
import { FormattedMessage, injectIntl } from "react-intl"
import { isRegistration, getRegistration, saveRegistration } from "./Registration"
import ImageWelcome from "./ImageWelcome"

/**
 * Modal ConfirmEmail Component
 */
class ConfirmEmail extends Component {

    /**
     * Create modal registration component
     */
    constructor(props) {
        super(props);
        this.state = {
          message: false
        };
      }

    componentDidMount() {
        const registration = getRegistration()
        if (registration && !registration.mail) {
            this.isConfirmed()  
        }
    }

    isConfirmed = (afterEvent = null) => {
        const event = this.props.event
        const registration = getRegistration()
        
        const url = `/api/v1/${event}/registration/isConfirmed/${btoa(registration.id)}`
        axios.get(url).then((res) => {
            if (isRegistration() && res.data.confirmed) {
                const registration = getRegistration()
                saveRegistration(registration.code, registration.id, registration.age, true, registration.date)
                this.props.onHide()
            }

            if (afterEvent != null) afterEvent(res.data.confirmed)
        })
    }

    onIsConfirmedClick = () => {
        this.isConfirmed((confirmed) => {
            if (!confirmed) {
                this.setState({message: "cofirmEmail.notConfirmed"})
            }
        })
    }

    /**
     * Send registration to backend
     * @param {*} e 
     */
    resendEmail = (e) => {
        e.preventDefault()
        const event = this.props.event
        const registration = getRegistration()
        const url = `/api/v1/${event}/registration/resend/`

        axios.post(url, { email: registration.id, locale: this.props.intl.locale})
            .then ((result) => {
                this.setState({message: "cofirmEmail.resended"})
            });
    }

    /**
     * Set field form value: TODO: create generation this
     */
    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    /**
     * Render modal registration
     */
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} 
                className="custom-modal" dialogClassName="cadastroModal">
                <div className="btn-close">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="row">
                    <div className="img-cadastro col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <ImageWelcome />
                    </div>
                    <div className="content-cadastro col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                        <h1 className="title h4 text-gray mb-4 pb-0"><FormattedMessage id="cofirmEmail.title" /></h1>

                        { this.state.message &&
                            <p><FormattedMessage id={this.state.message} /></p>
                        }
                        <form>
                            <div className="actions-modal">
                                <div className="row mb-3">
                                    <button type="button" className="btn text bold size-small text-white bg-orange col-sm-5 col-12" 
                                        onClick={() => {this.props.onHide(1)}}><FormattedMessage id="cofirmEmail.edit" /></button>
                                    <button type="button" className="btn text bold size-small text-white bg-orange col-sm-5 col-12" 
                                        onClick={this.resendEmail}><FormattedMessage id="cofirmEmail.resend" /></button>
                                </div>
                                <div className="row">
                                    <button type="button" className="btn text bold size-small text-white bg-orange col-sm-5 col-12" 
                                        onClick={this.onIsConfirmedClick}><FormattedMessage id="cofirmEmail.confirm" /></button>
                                    <button type="button" className="btn text bold size-small text-white bg-orange col-sm-5 col-12"
                                        onClick={this.props.onHide}><FormattedMessage id="close" /></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default injectIntl(ConfirmEmail);