import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

import img_logo from '../assets/images/logo-gamescom-latam-big.svg'


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nextEvent: null,
            description: ""
        };
    }
    
    componentDidMount() {
        const siteConfig = JSON.parse(localStorage.getItem("@bigjogos/site/"))

        this.setState({
            description: siteConfig?.header_description
        })
    }


    renderNextEvent() {
        const nextEvent = this.state.nextEvent
        const classCurrent = nextEvent?.current ? " current" : ""

        if (nextEvent) {
            return (
                <a className={`nextEvent${classCurrent}`} rel="noreferrer" href={nextEvent.location?.link} target="_blank" alt={nextEvent.description}>
                    <p className="eventDate">{nextEvent.day?.human}</p>
                    <p className="eventTime">{nextEvent.starts_at_hour}</p>
                    <p className="eventNow"><FormattedMessage id="menu.now" /></p>
                    <p className="eventName">{nextEvent.title}</p>
                </a>
            )
        }
        else {
            return ""
        }
    }

    render() {
        return (
            <header className="header-page">
              <div className="container">
                  <section className="content-title mb-3">
                      <figure className="mb-0">
                          <img src={img_logo} alt="Gamescom Latam - BIG Festival"/>
                      </figure>
                      <h1 className="title">Gamescom Latam - BIG Festival</h1>
                  </section>
                  <section className="content-subtitle">
                      <p className="text size-default text-gray mb-0 pb-0">{this.state.description}</p>
                  </section>
              </div>
          </header>
        )
    }
}

export default injectIntl(Header);