import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import imgRegistration from '../assets/images/img-cadastro.jpg'
import imgRegistrationMob from '../assets/images/img-cadastro-mob.jpg'

import imgGame from '../assets/images/img-cadastro_BIG.jpg'
import imgGameMob from '../assets/images/img-cadastro-mob_BIG.jpg'

import imgRegistrationEn from '../assets/images/img-cadastro-en.jpg'
import imgRegistrationMobEn from '../assets/images/img-cadastro-mob-en.jpg'

import imgGameEn from '../assets/images/img-cadastro_BIG-en.jpg'
import imgGameMobEn from '../assets/images/img-cadastro-mob_BIG-en.jpg'

class ImageWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vip: props.vip ?? false
        }
    }

    getImages = () => {
        if (this.state.vip) {
            return this.getImagesVip()
        }

        return this.getImagesRegistration()
    }

    getImagesVip = () => {
        let img = imgRegistration
        let imgMob = imgRegistrationMob

        if (this.props.intl.locale.split('-')[0] === "en") {
            img = imgRegistrationEn
            imgMob = imgRegistrationMobEn
        }

        return {img, imgMob}
    }

    getImagesRegistration = () => {
        let img = imgGame
        let imgMob = imgGameMob

        if (this.props.intl.locale.split('-')[0] === "en") {
            img = imgGameEn
            imgMob = imgGameMobEn
        }

        return {img, imgMob}
    }


    render() {
        const { img, imgMob } = this.getImages()

        return (
            <div className="img-cadastro">
                <img src={img} alt="BIG Festival - Cadastro" className="d-none d-sm-block d-md-block d-lg-block d-xl-block"/>
                <img src={imgMob} alt="BIG Festival - Cadastro" className="d-block d-sm-none"/>
            </div>
        )
    }
}

export default injectIntl(ImageWelcome);