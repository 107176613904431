import axios from 'axios';
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import { validateAge } from "./Registration";
import { FormattedMessage, injectIntl } from "react-intl";

import media_discord from '../assets/images/media-discord.svg';
import media_facebook from '../assets/images/media-facebook.svg';
import media_twitter from '../assets/images/media-twitter.svg';
import media_instagram from '../assets/images/media-instagram.svg';
import media_linkedin from '../assets/images/media-linkedin.svg';
import media_youtube from '../assets/images/media-youtube.svg';
import media_tiktok from '../assets/images/media-tiktok.svg';


/**
 * Modal PlayGame Component
 */
class PlayGame extends Component {

    /**
     * Create modal registration component
     */
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            cupom: '',
            message: '',
        };
      }

    /**
     * Send registration to backend
     * @param {*} e 
     */
    sendRegistration = (e) => {
        e.preventDefault();
    
        const { email, cupom } = this.state;
        

        if (cupom) {
            axios.post('/api/v1/vip/', { email, cupom })
            .then ((result) => {
                if (result.status === 200 && result.data.vip) {
                    this.props.onHide();
                }
                else {
                    const message = <FormattedMessage id="vip.noCupom" />
                    this.setState({ message })
                }
            });
        }
    }

    /**
     * Set field form value: TODO: create generation this
     */
    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }
    toggleTextDisplay = () => {
        let contentAboutActive = !this.state.contentAboutActive
        this.setState({contentAboutActive})
    } 
    /**
     * Render modal registration
     */
    renderToPlay() {
        let textButton = <FormattedMessage id="play" />

        if (!this.props.playLink)
            textButton = <FormattedMessage id="close" />


        return (
            <div className="content-instrucoes">
                <div className={`moreText mb-4 ${(this.state.contentAboutActive ? "show":"")}`} dangerouslySetInnerHTML={{__html: this.props.instructions}} />
                <button className="showMoreText" onClick={this.toggleTextDisplay}>{(this.state.contentAboutActive ? "-":"+")}</button>
                <form>        
                    <div className="actions d-flex flex-column">
                        <button type="button" 
                            onClick={() => this.props.onHide(1)} 
                            className="btn text bold size-small text-white bg-orange w-100 w-lg-25 mb-3">{textButton}</button>
                    </div>
                </form>
            </div>
        );
    }


    renderNoAge() {
        return (
            <div className="content-instrucoes">
                <p><FormattedMessage id="playGame.noAge" /></p>
                <form>
                    <div className="actions-modal">
                        <div className="row">
                            <button type="button" className="btn text bold size-small text-white bg-orange col-md-4 col-sm-6 col-12" 
                                onClick={() => {this.props.onHide(2)}}><FormattedMessage id="playGame.edit" /></button>
                            <button type="button" className="btn text bold size-small text-white bg-orange col-md-4 col-sm-6 col-12" 
                                onClick={this.props.onHide}><FormattedMessage id="close" /></button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => {this.props.onHide(0)}}
                className="custom-modal" dialogClassName="cadastroModal">
                <div className="btn-close">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row">
                    <div className="content-cadastro">
                        <div className="content-redes-sociais">
                            <h1 className="title"><FormattedMessage id="playGame.title" /></h1>
                            <p><FormattedMessage id="playGame.title.small" /></p>
                            <div className="links-redes-sociais">
                                <div className="discord mb-3">
                                    <a href="http://discord.gg/bigfestival" target="_blank" rel="noreferrer" title="Discord">
                                        <img src={media_discord} alt="Discord" style={{width: '45px'}} />
                                    </a>
                                </div>
                                <strong><FormattedMessage id="playGame.like" /></strong>
                                <div className="d-flex flex-row align-items-center social mb-3">
                                    <a href="https://www.facebook.com/BIGBIGFESTIVAL/" target="_blank" title="Facebook" rel="noreferrer">
                                        <img src={media_facebook} alt="Facebook" className="socialImage" />
                                    </a>
                                    <a href="https://twitter.com/BIG_Festival" target="_blank" title="Twitter" rel="noreferrer">
                                        <img src={media_twitter} alt="Twitter" className="socialImage" />
                                    </a>
                                    <a href="https://www.instagram.com/big_festival/" target="_blank" title="Instagram" rel="noreferrer">
                                        <img src={media_instagram} alt="Instagram" className="socialImage" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/bigfestival/" target="_blank" title="Linkedin" rel="noreferrer">
                                        <img src={media_linkedin} alt="Linkedin" className="socialImage" />
                                    </a>
                                    <a href="https://www.youtube.com/user/bigfestivalbrasil/videos" target="_blank" title="YouTube" rel="noreferrer">
                                        <img src={media_youtube} alt="YouTube" className="socialImage" />
                                    </a>
                                    <a href="https://www.tiktok.com/@bigfestival" target="_blank" title="TikTok" rel="noreferrer">
                                        <img src={media_tiktok} alt="TikTok" className="socialImage" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        { (validateAge(this.props.age) || !this.props.playLink) && this.renderToPlay() }
                        { (!validateAge(this.props.age) && this.props.playLink) && this.renderNoAge() }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default injectIntl(PlayGame);