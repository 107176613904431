import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import { GetAgeClass, GetAgeSmallLabel } from '../components/DataHelper';

/**
 * Show game classification
 */
class Classification extends Component {

  /**
   * Render classification component
   */
  render() {
    let age = 0;

    if (this.props.age)
      age = this.props.age;

    return (
      <div className={`age color ${GetAgeClass(Number(age))}`}>
          <p className="text text-white size-small mb-0">{GetAgeSmallLabel((Number(age)))}</p>
      </div>
    );
  }
}

export default injectIntl(Classification);