import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import { FormattedMessage, injectIntl } from "react-intl";
import { getStartDate, getStartDateVip } from "./siteData"
import axios from 'axios';
import ImageWelcome from "./ImageWelcome"

import ReCAPTCHA from "react-google-recaptcha"


/**
 * Modal Vip Component
 */
class Vip extends Component {

    /**
     * Create modal registration component
     */
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            cupom: '',
            message: '',
            token: "",
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
      }

    componentDidMount() {
        this.timerID = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    tick = () => {
        const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

        let now = new Date().getTime()
        let countDown = getStartDate().valueOf()
        let distance = countDown - now

        const days    = Math.floor(distance / (day))
        const hours   = Math.floor((distance % (day)) / (hour))
        const minutes = Math.floor((distance % (hour)) / (minute))
        const seconds = Math.floor((distance % (minute)) / second)

        this.setState({days, hours, minutes, seconds})
    }

    /**
     * Send registration to backend
     * @param {*} e 
     */
    sendRegistration = (e) => {
        e.preventDefault();
    
        const { email, cupom, token } = this.state;

        if (!token) {
            const message = <FormattedMessage id="vip.robot" />
            this.setState({ message })
            return
        }

        if (cupom) {
            let event = this.props.event
            const url = `/api/v1/${event}/vip/`
            axios.post(url, { email, cupom, token })
            .then ((result) => {

                let dateFormat = this.props.intl.locale.split('-')[0]

                if (dateFormat === "en")
                    dateFormat = "MM/DD"
                else
                    dateFormat = "DD/MM"

                if (result.status === 200 
                    && result.data.vip
                    && result.data.captcha) {
                    this.props.onHide();
                }
                else if (!result.data.captcha) {
                    const message = <p><FormattedMessage id="vip.robot" /></p>
                    this.setState({ message })
                    window.grecaptcha.reset()
                }
                else {
                    let message = ""

                    if (result.data.type === 1)
                        message = <p><FormattedMessage id="vip.futureCupom" /> {getStartDateVip().format(dateFormat)}</p>
                    else if (result.data.limit)
                        message = <p><FormattedMessage id="vip.limitCupom" /></p>
                    else
                        message = <p><FormattedMessage id="vip.noCupom" /></p>

                    this.setState({ message })
                    window.grecaptcha.reset()
                }
            });
        }
    }

    /**
     * Set field form value: TODO: create generation this
     */
    onChangeField = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked });
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    multiLines = (nodes) => {
        return nodes.map((node) => {
            if (typeof node === 'string')
                return node.split('\n').map((text, index) => {
                    return <p key={`text_${index}`}>{text}</p>
                })
            
            return ""
        })
    }

    setToken = (token) => {
        this.setState({token})
    }

    /**
     * Render modal registration
     */
    render() {
        

        return (
            <Modal show={this.props.show} onHide={()=>{}}
                className="custom-modal" dialogClassName="cadastroModal">
                <div className="btn-close">
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={this.props.onHide}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="row">
                    <div className="img-cadastro col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <ImageWelcome vip={true} />
                    </div>
                    <div className="content-cadastro col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                        <div className="countdown">
                            <ul>
                                <li><span id="days">{this.state.days}</span> <FormattedMessage id="days" /></li>
                                <li><span id="hours">{String(this.state.hours).padStart(2, "0")}</span> <FormattedMessage id="hours" /></li>
                                <li><span id="minutes">{String(this.state.minutes).padStart(2, "0")}</span> <FormattedMessage id="minutes" /></li>
                                <li><span id="seconds">{String(this.state.seconds).padStart(2, "0")}</span> <FormattedMessage id="seconds" /></li>
                            </ul>
                        </div>
                        <div className="title"><FormattedMessage id="vip.title">{(message) => { return this.multiLines(message)}}</FormattedMessage></div>
                        <form onSubmit={this.sendRegistration}>
                            <div className="form-group">
                                <FormattedMessage id="coupon">{(message) => { return (<input type="text" name="cupom" className="form-control" aria-describedby="emailHelp" placeholder={message} value={this.state.cupom} onChange={this.onChangeField}></input>)}}</FormattedMessage>
                            </div>
                            <div className="form-group">
                                <FormattedMessage id="email">{(message) => { return (<input type="text" name="email" className="form-control" aria-describedby="emailHelp" placeholder={message} value={this.state.email} onChange={this.onChangeField}></input>)}}</FormattedMessage>
                            </div>
                            <div className="form-group">
                                <ReCAPTCHA sitekey="6LcuzJUaAAAAALQTS6n9NeVXxFNrWpYZY7loztLW" onChange={this.setToken} />
                            </div>
                            <div className="form-group">
                                {this.state.message}
                            </div>
                            <button type="submit" className="btn btn-primary"><FormattedMessage id="enter" /></button>
                            <a href="https://www.bigfestival.com.br" className="btn learnmore" target="_new"><FormattedMessage id="vip.learnmore" /></a>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default injectIntl(Vip);