import React from 'react';
import axios from 'axios';
import BasePage from "./BasePage";
import { injectIntl, FormattedMessage } from "react-intl";
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderList from '../components/HeaderList';
import { isRegistration, getRegistration, saveRegistration } from '../components/Registration';

class ConfirmEmail extends BasePage {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        let event = this.props.event
        let url = `/api/v1/${event}/registration/confirm/`
        let code = atob(this.props.mail)
        console.log(code)



        axios.post(url, {email: atob(this.props.mail)}).then((res) => {
            if (isRegistration()) {
                const registration = getRegistration()
                saveRegistration(registration.code, registration.id, registration.age, true, registration.date)
            }
            this.setState({isLoading: false})
        })
    }


    render() {
        return (
            <main className="flex-shrink-0">
                <section className="highlight mb-4">
                    <div className="container">
                        <section className="content-title mb-4">
                            <h1 className="title h1 text-white text-center mb-0 pb-0">
                                {this.state.isLoading && <FormattedMessage id="confirm.loading" /> }
                                {!this.state.isLoading && <FormattedMessage id="confirm.ok" /> }
                            </h1>
                        </section>
                    </div>
                </section>
            </main>
        );
    }
}

export default injectIntl(ConfirmEmail);
