//DEV
/*const config = {
    baseUrl: '',
    baseApiURL: '',
    isIframe: false 
}*/

//games-festival
const config = {
  baseUrl: '',
  baseApiURL: '',
  //isIframe: false
  isIframe: true
}
  
export default config;